import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PhoneSection = ({ faq, buddy = true , textData, customImage}) => {
  const { t } = useTranslation();

  const section = buddy ? "_buddy" : "";
  const title = textData && textData.title ? textData.title : t(`phones.title${section}`);
  const description = textData && textData.description ? textData.description : t(`phones.desc${section}`);
  const imageSource = customImage ? "bg-phonesFaq" : "bg-phones";

  return (
    <>
      <div
        className={`${
          faq ? "bg-babymamGray-100" : "bg-babymam-100"
        } ${imageSource} bg-cover bg-top  bg-no-repeat  hidden md:block overflow-hidden`}
      >
        <div className="w-full justify-center text-center  max-w-[1728px] mx-auto">
          <div
            className={`${
              faq ? "bg-babymamGray-100" : "bg-babymam-100"
            } w-6/12 rounded mx-auto  p-10`}
          >
            <div className="w-full flex justify-center items-center mx-auto ">
              <img
                src={require("../../images/babymam-icon.png")}
                alt="icon"
                className="w-[80px] h-[80px]"
              ></img>
            </div>
            <div className="text-babymam-900">
              <h2 className="text-[24px] md:text-[30px] font-bold font-serif text-babymam-900 leading-[30px] md:leading-normal">
                { title}
              </h2>
              <p className="mt-4 text-[18px] md:text-[20px] leading-[23px] md:leading-normal">
                {description}
              </p>
              <p className="mt-4 text-[18px] md:text-[20px] font-semibold md:leading-normal">
                {!buddy && t("phones.join")}
              </p>
            </div>
            <div className="flex flex-row justify-center mt-10">
              <Link to="https://play.google.com/store/apps/details?id=it.babymam" target="_blank">
                <img
                  className="mr-10"
                  src={require("../../images/google.png")}
                  alt="google"
                ></img>
              </Link>
              <Link
                to="https://apps.apple.com/app/id1544439688"
                target="_blank"
              >
                <img src={require("../../images/apple.png")} alt="apple"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE */}

      <div className="md:hidden">
        <div className="w-full justify-center text-center">
          <img
            className="w-full"
            src={require("../../images/footer-mobile.png")}
            alt="phones"
          ></img>
          <div className="p-5 md:p-10">
            <div className="w-full flex justify-center items-center mx-auto ">
              <img
                src={require("../../images/babymam-icon.png")}
                alt="icon"
                className="w-[50px] h-[50px]"
              ></img>
            </div>
            <div className="text-babymam-900">
              <h2 className="text-[24px] font-bold font-serif text-babymam-900 leading-[30px]">
                {t("phones.title")}
              </h2>
              <p className="mt-4 text-[18px] text-babymamGray-700 leding-[18px]">
                {t("phones.desc")}
              </p>
            </div>
            <div className="flex flex-col items-center mt-6">
            <Link to="https://play.google.com/store/apps/details?id=it.babymam" target="_blank">
              <img
                src={require("../../images/google.png")}
                alt="google"
                className="w-[190px]"
              ></img>
              </Link>
              <Link  to="https://apps.apple.com/app/id1544439688" target="_blank">
              <img
                src={require("../../images/apple.png")}
                alt="apple"
                className="w-[190px] mt-[10px]"
              ></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneSection;
