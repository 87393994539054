import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "./Checkbox";
import Input from "./Input";

const UpgradeForm = () => {
  const { t } = useTranslation();

  const [ssNumber, setSSNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const isButtonDisabled = ssNumber.trim() === "" || isChecked === false;

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate mandatory fields
    const errors = {};

    if (ssNumber.trim() === "") {
      errors.ssNumber = "Social Security Number is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully!");
    }
  };

  const checkLabel = <span>{t("checkout.confirm")}</span>;

  return (
    <form onSubmit={handleSubmit} className="text-sm">
      <div>
        <Input
          label="Social Security Number"
          type="text"
          id="ssNumber"
          placeholder="Enter your Social Security Number"
          value={ssNumber}
          onChange={(e) => setSSNumber(e.target.value)}
          mandatory={true}
        />
        {errors.ssNumber && (
          <p className="text-babymam-600">{errors.ssNumber}</p>
        )}
      </div>

      <div className="py-6">
        <Checkbox
          label={checkLabel}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>

      <button
        className={`${
          isButtonDisabled
            ? "bg-babymamGray-300 cursor-not-allowed"
            : "bg-babymam-600"
        } w-full rounded  text-white py-3 px-5 rounded mr-2 uppercase`}
        disabled={isButtonDisabled}
      >
        {t("buttons.next")}
      </button>
      {/* TODO: CREATE A MESSAGE WHEN SENT */}
    </form>
  );
};

export default UpgradeForm;
