/* global fbq */

import React, { useEffect, useState } from "react";
import Checkbox from "../components/Checkbox";
import SignForm from "../components/SignUpForm";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";
import UpgradeForm from "../components/UpgradeForm";
import { useLocation, useNavigate } from "react-router-dom";
import NewStepper from "../components/NewStepper";
import DetailsForm from "../components/DetailsForm";
import PaymentForm from "../components/PaymentForm";
import ConfirmationPayment from "../components/ConfirmationPayment";
import { loadStripe } from "@stripe/stripe-js";
import apiFactory from "../helpers/apiFactory";
/* import Wrapper from "../components/Wrapper";*/
import { Elements } from "@stripe/react-stripe-js";
//import { checkLanguage } from "../utils/checkLanguage";
import { useLanguage } from "../contexts/LanguageContext";
import Spinner from "../components/Spinner";
import InsuranceHelper from "../helpers/InsuranceHelper";

const Checkout = () => {
  const { t } = useTranslation();
  const { country, setCountry } = useLanguage();
  const [data, setData] = useState({});
  const [key, setKey] = useState(null);
  const [options, setOptions] = useState({});
  const [stripePromise, setStripePromise] = useState("");
  const location = useLocation();
  const [language, setLanguage] = useState();
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const plan = location.state?.plan;

  const today =
    new Date().getDate() +
    "/" +
    (new Date().getMonth() + 1) +
    "/" +
    new Date().getFullYear();
  const tomorrow =
    new Date().getDate() +
    1 +
    "/" +
    (new Date().getMonth() + 1) +
    "/" +
    new Date().getFullYear();
  const nextYear =
    new Date().getDate() +
    1 +
    "/" +
    (new Date().getMonth() + 1) +
    "/" +
    (new Date().getFullYear() + 1);

  const [isChecked, setIsChecked] = useState(false);
  const [isFormDetails, setIsFormDetails] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const navigate = useNavigate();
  const [title, setTitle] = useState("checkout.complete_data");
  const [subtitle, setSubtitle] = useState("");
  const [form, setForm] = useState({});
  //const [currentPrice, setCurrentPrice] = useState(0);
  //const [currentVATPercentage, setCurrentVATPercentage] = useState("");
  //const [currentVAT, setCurrentVAT] = useState("");
  //const [currentPremie, setCurrentPremie] = useState(0);
  const [policyNumber, setPolicyNumber] = useState("");
  const [externalID, setExternalID] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [coverageEndDate, setCoverageEndDate] = useState("");
  const [validFromDate, setValidFromDate] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [documentList, setDocumentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    t("confirmationsteps.step1"),
    t("confirmationsteps.step2"),
    t("confirmationsteps.step3"),
    t("confirmationsteps.step4"),
  ];

  const handleForm = (data) => {
    setForm(data);
    setIsFormDetails(true);
    handleClick("next");
    setTitle("checkout.correct");
    setSubtitle("checkout.correct_subtitle");
  };

  const handleEdit = (data) => {
    handleClick("back");
    setData(data);
    setIsFormDetails(false);
    setTitle("checkout.complete_data");
    // setSubtitle("checkout");
  };

  const handleConfirmation = () => {
    handleClick("next");
    setIsPayment(false);
    setIsConfirmation(true);

    const code = language.toLowerCase();
    navigate(`/${code}/product/purchase`);
        
    //meta tracking
    fbq('track', 'Purchase', {value: InsuranceHelper.getTotal(), currency: 'EUR'});
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const textPlan =
    plan === "basic" ? "Basic plan" : t("confirmationsummary.insurance");
  const price = plan === "basic" ? 0 : 100;
  const checkLabel = (
    <span>
      I have a Pregnant insurance Base and I want to
      <span className="font-semibold"> Upgrade</span>
    </span>
  );

  const [currentStep, setCurrentStep] = useState(1);
  const stepArray = [
    t("checkoutstepper.step1"),
    t("checkoutstepper.step2"),
    t("checkoutstepper.step3"),
    t("checkoutstepper.step4"),
  ];
  const handleClick = (clickType) => {
    let newStep = currentStep;
    clickType === "next" ? newStep++ : newStep--;
    // Check if steps are within the boundary
    if (newStep > 0 && newStep <= stepArray.length) {
      setCurrentStep(newStep);
    }
  };

  const formatDate = (dateStr) => {
    console.log("formatDate = ", dateStr)
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    switch (country) {
      case "DE":
        return new Intl.DateTimeFormat('de-DE').format(date);
      default:
        return `${day}/${month}/${year}`;
    }    
  };

  const [insuranceDetails, setInsuranceDetails] = useState("");

  const handlePayment = async () => {
    handleClick("next");
    setIsLoading(true);
    const api = apiFactory();
    // let timeoutId = setTimeout(() => {
    //   setIsLoading(false);
    // }, 4000);

    try {
      const res = await api.createInsurance(form);
      setKey(res.paymentReference);
      setOptions({
        clientSecret: res.paymentReference,
      });
      setExternalID(res.externalID);
      setStripePromise(loadStripe(res.paymentKey));
      
      api.getInsurance(res.externalID)
        .then((response) => {
          console.log("getInsurance :: ", JSON.stringify(response));
          console.log("getInsurance :: dateOfPurchase ", response.dateOfPurchase);
          setInsuranceDetails(response);
          setPolicyNumber(response.policyNumber);
          setPurchaseDate(formatDate(response.dateOfPurchase));
          setCoverageEndDate(formatDate(response.coverageEndDate));
          setValidFromDate(formatDate(response.validFromDate));
          setExpectedDeliveryDate(formatDate(response.expectedDeliveryDate));
          setDocumentsList(response.documentList);
          setIsPayment(true);
          setIsFormDetails(false);
          setTitle("checkout.payment");
          setSubtitle("");
          setIsLoading(false);

          //store response for purhcase step
          sessionStorage.setItem('insuranceData', JSON.stringify(response));

        });
       
    } catch (error) {
      console.error("Error API:", error);
      setIsLoading(false);
      handleClick("back");
    } finally {
      setIsLoading(false);
    }
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/[\s_-]+/g, "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const getDocumentUrl = (documentType) => {
    if(insuranceDetails==null) return "";

    let documentURL = null;
    const document = insuranceDetails.documentList.find(
      (doc) =>
        normalizeString(doc.documentType) ===  normalizeString(documentType)
    );

    if (document) {
      return document.documentURL;
    } else {
      return ""
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLanguage(country);
    setIsLanguageChanged(true);
  }, [country]);

  useEffect(() => {
    if (isLanguageChanged) {
      const code = language.toLowerCase();
      navigate(`/${code}/`);
    }

    setIsLanguageChanged(false);
  }, [isLanguageChanged]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPayment, isLoading]);

  return (
    <>
      <div className="w-full md:px-[60px] justify-center justify-items-center ">
        <div className="md:mb-20 md:px-20 max-w-[1728px] mx-auto">
          <NewStepper steps={stepArray} currentStepNumber={currentStep} />
        </div>

        {isConfirmation && (
          <div className="p-5 md:mx-auto md:w-6/12 justify-center text-center max-w-[1728px] mx-auto ">
            <div className="max-w-[1728px] mx-auto">
              <p className="text-[16px] md:text-[18px] text-babymam-900 font-semibold mb-4">
                {t("confirmation.title")}
              </p>
              <h2 className="text-[26px] md:text-[36px] font-bold font-serif text-babymam-900">
                {t("confirmation.subtitle")}
              </h2>
              <p className="mt-4 text-[18px] md:text-[20px]">
                {t("confirmation.text")}
              </p>
            </div>
            <div
              className={`${
                plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
              } mt-10 rounded-lg px-10 py-4`}
            >
              <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[28px]">
                {t("confirmation.pregnancy")}
              </div>
              <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
                <div className="flex flex-row justify-between">
                  <span className="text-[16px] md:text-[20px] text-left">
                    {t("confirmation.policynumber")}{" "}
                  </span>
                  <span className="font-semibold text-right">
                    {policyNumber}
                  </span>
                </div>
                <div className="mt-4 flex flex-row justify-between">
                  <span className="text-[16px] md:text-[20px] text-left">
                    {t("confirmation.date")}{" "}
                  </span>
                  <span className="font-semibold text-right">{purchaseDate}</span>
                </div>
                <div className="mt-4 flex flex-row justify-between">
                  <span className="text-[16px] md:text-[20px] text-left">
                    {t("confirmation.expected")}{" "}
                  </span>
                  <span className="font-semibold text-right">
                    {expectedDeliveryDate}
                  </span>
                </div>
                {country === "FR" ? ( // Variant for France
                <div className="mt-4 flex flex-col justify-between">
                  <span className="text-[16px] md:text-[20px] text-left w-full">
                    {t("confirmation.policystart")}{" "}
                  </span>
                </div>
                ) : (  // Variant for other countries
                <div className="mt-4 flex flex-row justify-between">
                  <span className="text-[16px] md:text-[20px] text-left">
                    {t("confirmation.policystart")}{" "}
                  </span>
                  <span className="font-semibold text-right">
                    {validFromDate}
                  </span>
                </div>
                )}
                <div className="mt-4 flex flex-row justify-between">
                  <span className="text-[16px] md:text-[20px] text-left">
                    {t("confirmation.policyend")}{" "}
                  </span>
                  <span className="font-semibold text-right">
                    {coverageEndDate}
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-[#FFFFFF] mt-[40px] md:mt-[70px] relative">
              <h3 className="text-[22px] font-semibold text-left mb-5">
                {t("confirmation.happen")}
              </h3>

              <div>
                {steps.map((step, index) => (
                  <div key={index} className="relative flex items-start">
                    <div className="mr-2">
                      <div className="w-8 h-8 rounded-full bg-babymam-600 flex items-center justify-center text-white text-[16px] leading-[20px] md:leading-[30px] md:text-[18px]">
                        {index + 1}
                      </div>
                      {index < steps.length - 1 && (
                        <div className="absolute left-[15px] top-8 bottom-0 w-0.5 bg-gray-300  "></div>
                      )}
                    </div>

                    <p className="mb-5 text-start text-[16px] leading-[20px] md:leading-[30px] md:text-[18px]">
                      <Trans components={{ a: <a />, span: <span /> }}>
                        {step}
                      </Trans>
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-[#FFFFFF] mt-[40px] relative">
              <h3 className="text-[22px] font-semibold text-left mb-5">
                {t("confirmation.information")}
              </h3>

              <div className="flex flex-wrap justify-start mt-[30px] px-1 w-full gap-[20px] max-w-[738px]">

                {documentList.map((document, index) => (
                  <a
                    key={index}  // Add a key for each item in the list
                    className="flex items-start bg-white p-5 w-full shadow-[0_4px_10px_0px_rgba(0,0,0,0.12)] rounded-[10px]"
                    href={document.documentURL}
                    target="_blank"
                  >
                    <div className="flex-shrink-0">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 31 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.25 11.875H19.25V4.375H11.75V11.875H6.75L15.5 20.625L24.25 11.875ZM6.75 23.125V25.625H24.25V23.125H6.75Z"
                          fill="#BA5E98"
                        />
                      </svg>
                    </div>
                    <span className="ml-5 font-semibold text-[18px] leading-[30px] text-left">
                      {document.documentTitle}
                    </span>
                  </a>
                ))}                
              </div>

              <div className="mt-[40px]">
                <h3 className="text-[22px] font-semibold text-left mb-5">
                  {t("confirmation.question")}
                </h3>
                <div className="flex flex-col align-start space-y-5">
                  <a href={`${country}/faq`}>
                    <div className="flex">
                      <div className="text-left text-[16px] md:text-[18px] font-semibold uppercase text-babymam-600">
                        {t("confirmation.faqs")}
                      </div>
                      <div className="flex justify-center items-center ml-[10px]">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4.5L10.59 5.91L16.17 11.5H4V13.5H16.17L10.59 19.09L12 20.5L20 12.5L12 4.5Z"
                            fill="#BA5E98"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <a href={`${country}/contact`}>
                    <div className="flex">
                      <div className="text-left text-[16px] md:text-[18px] font-semibold uppercase text-babymam-600">
                        {t("confirmation.contact")}
                      </div>
                      <div className="flex justify-center items-center ml-[10px]">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4.5L10.59 5.91L16.17 11.5H4V13.5H16.17L10.59 19.09L12 20.5L20 12.5L12 4.5Z"
                            fill="#BA5E98"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!isConfirmation && (
        <div className="w-full p-5 md:p-10 bg-white md:bg-babymamGray-100 justify-center justify-items-center">
          <div className="justify-center text-center pb-5 md:pb-10 max-w-[1728px] mx-auto">
            <h2 className="headline-big">
              {t(title)}
            </h2>
            <p className="mt-4 text-[18px] md:text-[20px]">{t(subtitle)}</p>

            {isPayment && key != null && (
              <div className="md:mx-auto md:w-6/12 justify-center text-center">
                {/* <div>
                <h2 className="text-4xl font-bold font-serif text-babymam-900 text-[34px] md:text-[48px]">
                  Payment
                </h2>
              </div> */}
                <div
                  className={`${
                    plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
                  } mt-10 rounded-lg px-10 py-4`}
                >
                  <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[28px]">
                    {t("confirmationsummary.summary")}
                  </div>
                  <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
                    <div className="flex flex-row justify-between">
                      <span className="text-left">
                        {t("confirmationsummary.chosen")}{" "}
                      </span>
                      <span className="font-semibold text-right">
                        {textPlan}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between mt-5">
                      <span className="text-[14px] leading-[18px]">
                        {t("confirmationsummary.premie")}{" "}
                      </span>
                      <span className="text-[14px] leading-[18px]">
                        {InsuranceHelper.getPremium() } €
                      </span>
                    </div>
                    {country === "ES" ? (
                      <>
                        <div className="flex flex-row justify-between mt-[5px]">
                          <span className="text-[14px] md:text-[16px]  leading-[18px]">
                            Consorcio:{" "}
                          </span>
                          <span className="text-[14px] md:text-[16px] leading-[18px]">
                            { InsuranceHelper.getVATExt() } €
                          </span>
                        </div>
                        <div className="flex flex-row justify-between mt-[5px]">
                          <span className="text-[14px] md:text-[16px]  leading-[18px]">
                            L.E.A:{" "}
                          </span>
                          <span className="text-[14px] md:text-[16px] leading-[18px]">
                          { InsuranceHelper.getVATExt2() } €
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="flex flex-row justify-between">
                      <span className="text-[14px] leading-[18px]">
                        {t("confirmationsummary.vat")}{" "}
                      </span>
                      <span className="text-[14px] leading-[18px]">
                        {InsuranceHelper.getVAT()} €
                      </span>
                    </div>
                    <div className="mt-4 flex flex-row justify-between">
                      <span>{t("confirmationsummary.total")} </span>
                      <span className="font-semibold">{InsuranceHelper.getTotal()} €</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`${
              isPayment
                ? "flex-col-reverse items-center"
                : "flex-col md:flex-row"
            } flex md:px-10 justify-center  max-w-[1728px] mx-auto`}
          >
            {!isConfirmation && isLoading ? (
              <Spinner />
            ) : (
              <div className=" bg-white rounded-lg p-5 md:p-10 mb-6 md:w-6/12 ">
                {!isChecked &&
                  !isFormDetails &&
                  !isPayment &&
                  !isConfirmation && (
                    <SignForm
                      handleForm={handleForm}
                      data={data}
                      plan={plan}
                      language={language}
                    />
                  )}
                {isChecked &&
                  !isFormDetails &&
                  !isPayment &&
                  !isConfirmation && <UpgradeForm handlePaymnet={handleForm} />}
                {isFormDetails && (
                  <DetailsForm
                    form={form}
                    plan={plan}
                    handlePayment={handlePayment}
                    handleEdit={handleEdit}
                  />
                )}

                {isPayment && key != null && (
                  <Elements stripe={stripePromise} options={options}>
                    <PaymentForm handleConfirmation={handleConfirmation} />
                  </Elements>
                )}
              </div>
            )}

            {!isFormDetails && !isPayment && (
              <div
                className={`${
                  plan === "basic" ? "bg-babymamGray-200" : "bg-babymam-100"
                } rounded-lg px-5 py-4 h-fit ${
                  isPayment ? "mx-auto w-6/12 mb-10" : "md:w-4/12 md:ml-10"
                } sticky`}
                style={{ top: "140px" }}
              >
                <div className="border-b-2 border-babymam-400 pb-4 font-semibold text-babymam-900 text-[18px] md:text-[22px] text-center">
                  {t("signformsummary.summary")}
                </div>
                <div className="py-4 flex flex-col text-babymam-900 text-[18px] md:text-[20px]">
                  <div className="flex flex-col justify-between">
                    <span>{t("signformsummary.chosen")} </span>
                    <span className="font-semibold">{textPlan}</span>
                  </div>
                  <div className="flex flex-row justify-between mt-5">
                    <span className="text-[14px] md:text-[16px] leading-[18px]">
                      {t("signformsummary.premie")}{" "}
                    </span>
                    <span className="text-[14px] md:text-[16px] leading-[18px]">
                      {InsuranceHelper.getPremium()} €
                    </span>
                  </div>
                  {country === "ES" ? (
                    <>
                      <div className="flex flex-row justify-between mt-[5px]">
                        <span className="text-[14px] md:text-[16px]  leading-[18px]">
                          Consorcio:{" "}
                        </span>
                        <span className="text-[14px] md:text-[16px] leading-[18px]">
                        {InsuranceHelper.getVATExt()} €
                        </span>
                      </div>
                      <div className="flex flex-row justify-between mt-[5px]">
                        <span className="text-[14px] md:text-[16px]  leading-[18px]">
                          L.E.A:{" "}
                        </span>
                        <span className="text-[14px] md:text-[16px] leading-[18px]">
                        {InsuranceHelper.getVATExt2()} €
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-row justify-between mt-[5px]">
                    <span className="text-[14px] md:text-[16px]  leading-[18px]">
                      {t("signformsummary.vat")}{" "}
                    </span>
                    <span className="text-[14px] md:text-[16px] leading-[18px]">
                      {InsuranceHelper.getVAT()} €
                    </span>
                  </div>
                  <div className="mt-4 flex flex-row justify-between">
                    <span className="text-[18px] md:text-[20px] font-semibold">
                      {t("signformsummary.total")}{" "}
                    </span>
                    <span className="font-semibold">{InsuranceHelper.getTotal()} €</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
