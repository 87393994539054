import React from "react";

const Textarea = ({
  label,
  placeholder,
  value,
  onChange,
  mandatory = false,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-babymamGray-800 mb-2 text-[16px] md:text-[18px]" htmlFor={label}>
        {label} {mandatory && <span className="text-babymam-600">*</span>}
      </label>
      <textarea
        className="appearance-none text-[16px] md:text-[18px] resize-none border border-babymamGray-400 rounded w-full py-2 px-3 text-babymamGray-800 h-48 leading-tight focus:outline-none focus:shadow-outline"
        id={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Textarea;
