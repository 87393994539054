//import "./App.css"; //??? just crap?
import React from "react";
import { useNavigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Layout from "./components/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { checkLanguage } from "./utils/checkLanguage";
import { LanguageProvider } from "./contexts/LanguageContext";
import RedirectBasedOnCountry from "./components/RedirectBasedOnCountry";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CookiesPopup from "./components/CookiesPopup";
import { useLanguage } from "./contexts/LanguageContext";
import { ParamProvider, useParam } from "./contexts/ParamContext";
import apiFactory from "./helpers/apiFactory";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const CheckLanguage = () => {
  const { country, setCountry } = useLanguage();
  i18n.changeLanguage(country.toLowerCase());
  console.log("Language changed to:", country);
  /*useEffect(() => {
    if (country) {
      i18n.changeLanguage(country.toLowerCase());
      
    }
  }, [country]);

  return null;*/
};

const CaptureParam = () => {
  const { setParam } = useParam();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const t = searchParams.get("t");
    if (t) {
      setParam(t);
      localStorage.setItem("param", t);
    }
  }, [searchParams, setParam]);

  return null;
};

const App = () => {
  const navigate = useNavigate();

  // insurance prices
  const [prices, setPrices] = useState({});
  const countries = ['IT', 'ES', 'DE', 'FR'];
  const api = apiFactory();
  
  useEffect(() => {
    // Function to fetch prices for each country
    const fetchPrices = async () => {
      try {
        // Create an array of promises for each API call
        const priceRequests = countries.map(countryCode =>          
          api.insuranceInit(countryCode)
        );

        // Resolve all promises
        const responses = await Promise.all(priceRequests);

        // Map the responses to a price object
        const pricesData = responses.reduce((acc, response, index) => {
          acc[countries[index]] = response;
          return acc;
        }, {});

        setPrices(pricesData); // Save the prices in state
        localStorage.setItem("InsuranceInitData", JSON.stringify(pricesData));
      } catch (error) {
        console.error('Failed to fetch prices:', error);
      }
    };

    fetchPrices(); // Fire the API calls on component load

  }, []); // Empty dependency array ensures this runs only once on component load


  const handleCountryDetected = (countryCode) => {
    const currentPath = window.location.pathname;
    if (!currentPath.includes(`/${countryCode}`)) {
      navigate(`/${countryCode}${currentPath}`);
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <ParamProvider>
        <LanguageProvider onCountryDetected={handleCountryDetected}>
          <CaptureParam />
          <CheckLanguage />
          <ScrollToTop />
          <RedirectBasedOnCountry />
          <Layout />
          <ToastContainer />
          <CookiesPopup />
        </LanguageProvider>
      </ParamProvider>
    </I18nextProvider>
  );
};

export default App;
