import React, { useEffect, useRef, useState } from "react";
import Card from "../components/Card";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import Button from "../components/Button";
import StepperSection from "./sections/StepperSection";
import PhoneSection from "./sections/PhoneSection";
import useIsDesktop from "../utils/isDesktop";
import { useLanguage } from "../contexts/LanguageContext";
import BenefitsSection from "./sections/BenefitsSection";
import Testimonials from "./sections/Testimonials";
import StayConnected from "./sections/StayConnected";
import ArticlesSection from "./sections/ArticleSection";
import FAQSection from "./sections/FAQSection";
import PromotionSection from "./sections/PromotionSection";
import InsuranceHelper from "../helpers/InsuranceHelper";
import LegalDocumentsPopup from "../components/LegalDocumentsPopup";

const OurInsurance = () => {
  const { t } = useTranslation();
  const { country } = useLanguage();
  const isDesktop = useIsDesktop();

  const legalDocumentsPopupRef = useRef();
  const showLegalDocumentsPopup = () => {
    legalDocumentsPopupRef.current.display(); // Show the popup
  };

  return (
    <>
      <div className="bg-babymamGray-100">
        <div className="relative flex flex-col items-center md:items-start justify-center md:h-[600px] md:overflow-hidden bg-cover bg-center"
          style={isDesktop ? { backgroundImage: `url(${require('../images/our_insurance/bg-hero.png')})` } : {}}>

          <img src={require("../images/our_insurance/bg-hero-mobile.png")} alt="Mobile Image" className="block md:hidden w-full h-auto object-cover" />

          <div className="p-8 md:w-1/2 text-left">
            <h1 className="headline-big">
            {t("our_insurance.hero_heading")}
            </h1>
            <h2 className="headline-sub mt-1">{t("our_insurance.hero_subheading")}</h2>
            <p className="text-[18px] md:text-[20px] mt-4">{t("our_insurance.hero_text")}</p>
            <div className="mt-10 mb-10">
              <div className="flex mt-8 hidden md:block">
                <NavLink to={"/product"} className="inline-flex">
                  <Button desk="20" mobile="18" fullWidth={false}>
                  {t("our_insurance.hero_button")}
                  </Button>
                </NavLink>
              </div>
              <div className="mt-8 text-center justify-center md:hidden">
                <NavLink to={"/product"}>
                  <Button desk="20" mobile="18">
                  {t("our_insurance.hero_button")}
                  </Button>
                </NavLink>
              </div>
              <div className="text-[14px] text-center md:text-left mt-4"> 
              {country === "FR" && (
                <Link className="text-purple-600 underline" onClick={showLegalDocumentsPopup}>
                {t('our_insurance.tc_apply')}
                </Link>
              )}        
              </div>
            </div>
          </div>        
        </div>

        <div className="flex justify-center px-8">
          {/* Container for centered content */}
          <div className="max-w-[1200px] w-full text-center">
            {/* Header section */}
            <div className="p-4 mt-4">
            <h2 className="headline-medium">{t("our_insurance.celebrate_heading")}</h2>
              <p className="mt-2 text-[18px] md:text-[20px] text-gray-700">
              {t("our_insurance.celebrate_text")}
              </p>
              {country === "FR" && (
                <p className="mt-2 text-[14px] text-gray-700 mb-4">
                  {t("our_insurance.celebrate_disclaimer")}
                </p>
              )}        
              {country === "FR" && (
                <Link className="text-purple-600 underline" onClick={showLegalDocumentsPopup}>
                {t('our_insurance.tc_apply')}
                </Link>
              )}        
              </div>

            {/* Three horizontal content blocks (responsive to stack on mobile) */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* First block */}
              <div className="text-left">
                <img src={require("../images/our_insurance/coverage_image1.png")} alt="Specialized Health Coverage" className="w-full h-auto rounded-lg" />
                <h2 className="mt-4 text-[22px] md:text-[22px] font-bold text-gray-800"> {t("our_insurance.benefit1_heading")}</h2>
                <p className="mt-2 text-[16px] md:text-[18px] text-gray-600">
                {t("our_insurance.benefit1_text")}
                </p>
              </div>

              {/* Second block */}
              <div className="text-left">
                <img src={require("../images/our_insurance/coverage_image2.png")} alt="Enhanced Protection Throughout Your Journey" className="w-full h-auto rounded-lg" />
                <h2 className="mt-4 text-[22px] md:text-[22px] font-bold text-gray-800">{t("our_insurance.benefit2_heading")}</h2>
                <p className="mt-2 text-[16px] md:text-[18px] text-gray-600">
                {t("our_insurance.benefit2_text")}
                </p>
              </div>

              {/* Third block */}
              <div className="text-left">
                <img src={require("../images/our_insurance/coverage_image3.png")} alt="Effortless and Accessible" className="w-full h-auto rounded-lg" />
                <h2 className="mt-4 text-[22px] md:text-[22px] font-bold text-gray-800">{t("our_insurance.benefit3_heading")}</h2>
                <p className="mt-2 text-[16px] md:text-[18px] text-gray-600">
                {t("our_insurance.benefit3_text")}
                </p>
              </div>
            </div>

            {/* Button section */}
            <div className="flex text-center mt-8 hidden md:block">
              <NavLink to={"/product"} className="inline-flex">
                <Button desk="20" mobile="18" fullWidth={false}>
                {t("our_insurance.benefit_button")}
                </Button>
              </NavLink>
            </div>
            <div className="mt-8 text-center justify-center md:hidden">
              <NavLink to={"/product"}>
                <Button desk="20" mobile="18">
                {t("our_insurance.benefit_button")}
                </Button>
              </NavLink>
            </div>
            <div className="text-[14px] text-center mt-4"> 
              {country === "FR" && (
                <Link className="text-purple-600 underline" onClick={showLegalDocumentsPopup}>
                {t('our_insurance.tc_apply')}
                </Link>
              )}          
            </div>       
          </div>
        </div>

      <div className="flex justify-center px-4 mt-10 pb-10">
          <BenefitsSection showLegalDocumentsPopup={showLegalDocumentsPopup} />
        </div>
      </div>

      {/*
      <div className="bg-babymam-100">
        <div className="flex justify-center px-4 mt-0">
          <Testimonials />
        </div>'
      </div>
      */}
         
      {/*
      <div className="bg-babymamGray-100">
        <div className="flex justify-center p-0 pt-10">
          <StayConnected />
        </div>
      </div>
      */}

      <div className="bg-white">
        <div className="flex justify-center px-4 mt-4">
          <ArticlesSection />
        </div>
      </div>

      
      <div className="w-full flex justify-center justify-items-center bg-babymamGray-100 px-4">
        <FAQSection />
      </div>


      <div className="w-full">
        <PromotionSection />
      </div>

      <LegalDocumentsPopup ref={legalDocumentsPopupRef} />
      
    </>
  );
};

export default OurInsurance;
