import React from "react";

const Checkbox = ({ checked, onChange, label, id }) => {
  return (
    <label className="flex items-start justify-center">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="appearance-none md:w-[18px] md:h-[18px] cursor-pointer rounded-sm checked:after:relative checked:after:ml-1 checked:after:bottom-[1px] checked:after:content-['✓'] checked:after:text-white ring-2 ring-babymam-600 checked:bg-babymam-600 mt-[5px]"
        style={{ minWidth: "18px", height: "18px" }} 
      />
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default Checkbox;
