import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css"; // Import Tailwind CSS
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "../DatePickerInput/DatePickerInput.css";

const DatePickerInput = ({
  label,
  id,
  value,
  onChange,
  mandatory = false,
  today = true,
}) => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 18);

  const handleChange = (e) => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate)) {
      onChange(newDate);
    } else {
      console.error("Invalid date format");
    }
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      id={id}
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      placeholder="DD/MM/YYYY"
      className="appearance-none text-[16px] md:text-[18px] border border-babymamGray-400 rounded w-full py-2 px-3 text-babymamGray-800 leading-tight focus:outline-none focus:shadow-outline"
    />
  );

  return (
    <div className="">
      <label
        className="block text-babymamGray-800 mb-2 text-[16px] md:text-[18px]"
        htmlFor={label}
      >
        {label} {mandatory && <span className="text-babymam-600">*</span>}
      </label>

      <div>
        <Calendar
          value={value}
          id={id}
          onChange={handleChange}
          inputClassName="appearance-none text-[16px] md:text-[18px] border border-babymamGray-400 rounded w-full py-2 px-3 text-babymamGray-800 leading-tight focus:outline-none focus:shadow-outline w-full"
          dateFormat="dd/mm/yy"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default DatePickerInput;
