import { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import StepperProduct from "../../components/StepperProduct";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../contexts/LanguageContext";
import { handleDownload } from "../../utils/handleDownload";
import InsuranceHelper from "../../helpers/InsuranceHelper";
import ContentHelper from "../../helpers/ContentHelper";

export const Table = ({ title, content }) => {
  return (
    <div className="bg-babymam-100 text-babymam-900   font-semibold rounded-t-[10px] rounded-b-[10px] mt-[10px]">
      <div className="w-full">
        <div className="border-b border-babymam-600 w-full px-[20px] py-[10px]">
          <span className=" text-[14px] md:text-[16px]">{title}</span>
        </div>

        <div className="px-5 py-[10px] ml-5 ">
          <ul className="list-disc ">
            {content.map((item, index) => (
              <li
                key={index}
                className="text-[14px] md:text-[16px] leading-[18px] md:leading-[24px]"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const MobileTableComp = ({
  title,
  price,
  content,
  listLeft,
  listRight,
  component,
  component2,
  text,
  paid = false,
  plan,
}) => {
  const { country } = useLanguage();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const defaultTransformComponents = {
    span: <span />,
    ul: <ul />,
    li: <li />,
    p: <p />,
    br: <br />,
    a: <a/>,
    div:<div/>,
    table:<table/>,
    td:<td/>,
    tr:<tr/>,
    thead:<thead/>,
    th:<th/>,
    tbody:<tbody/>
  }

  return (
    <div className="flex flex-col border-b  border-babymamGray-200 bg-white last:border-b-0 max-w-[1728px] mx-auto w-full rounded-b-[10px]">
      <div className="flex flex-row">
        <div className="w-full px-5 py-[15px] mt-1">
          <button
            className="text-babymamGray-700 mb-2 flex w-full justify-between items-center"
            onClick={toggleCollapse}
          >
            <div className="text-left flex items-center">
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`mr-[15px] min-w-[13px] min-h-[8px] transform transition-transform ${
                  isCollapsed ? "rotate-0" : "rotate-180"
                }`}
              >
                <path
                  d="M11.09 0.294922L6.5 4.87492L1.91 0.294922L0.5 1.70492L6.5 7.70492L12.5 1.70492L11.09 0.294922Z"
                  fill="#BA5E98"
                />
              </svg>

              <span className="text-left font-semibold text-[18px] leading-[30px] mr-5 md:mr-0 w-full">
                {title}
              </span>
            </div>
            {/*
            <span className="text-right  font-semibold text-[18px] leading-[30px] min-w-[78.6px] md:ml-5 ">
              {price}
            </span>
            */}
            
          </button>
        </div>
      </div>
      {isCollapsed ? null : (
        <div className="px-5 pb-5  bg-white text-babymamGray-800 text-[18px] leading-[30px]" dangerouslySetInnerHTML={{ __html: ContentHelper.transformAppContent(content, country) }} />
      )}
    </div>
  );
};

const TableSection = ({ title }) => {
  const { t } = useTranslation();
  const features = t('features', { returnObjects: true }) || [];
  const { country, insuranceHelperLoading } = useLanguage();

  const [selectedPlan, setSelectedPlan] = useState("paid");
  const checkoutUrl = `/${country}/product/checkout`;

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const printPriceForCheckout = () => {
    if(!insuranceHelperLoading) {
     return InsuranceHelper.getTotal();    
    }   
  }
  
  const handleButtonClick = () => {
   
    if (window.gtag) {
      window.gtag('event', 'click', {
        'event_category': 'Button',
        'event_label': 'SignUpBanner'
      });
    }
  };

  return (
    <div className="w-full  md:bg-babymamGray-100  ">
      <div className=" p-5 md:p-20 pt-5 md:pt-10 bg-white md:bg-babymamGray-100 justify-center justify-items-center max-w-[1728px] mx-auto ">
        {title && (
          <div className="w-full justify-center text-center">
            <div className="md:w-full mx-auto">
              <h2 className="headline-big">
                {t("product.title")}
              </h2>
              <p className="mt-4 text-[18px] md:text-[20px] font-normal mb-5 md:mb-5">
                {t("product.subtitle")}
              </p>
            </div>
          </div>
          )}
        <div className="w-full p-5 md:p-10 justify-center justify-items-center max-w-[1728px] mx-auto">
            <div className="md:px-20 justify-center">
                {<StepperProduct />}
            </div>
        </div>
        
        <div className="flex w-full flex-col md:flex-row items-center md:items-stretch">
          <div className=" w-full max-w-[738px] ">
            <div className="w-full shadow-[0_4px_10px_0px_rgba(0,0,0,0.12);] rounded-[10px] max-w-[738px]">
              <div className="bg-babymam-100 text-babymam-900 flex justify-between p-5 text-[18px] md:text-[20px] font-semibold border-b border-babymamGray-400 rounded-t-[10px]">
                <span>{t("product.table_title")}</span>
              </div>
              <div></div>
              <div className=" flex justify-between border-b border-babymamGray-200 bg-babymamGray-200">
                <div className="bg-babymamGray-200 text-left  w-1/4 p-2 md:pl-[15px]  text-babymamGray-800 font-semibold text-[20px]  block rounded-t-xl">
                  {t("product.table_desc")}
                </div>
              </div>
              {Array.isArray(features) && features.map((item, index) => (
                <MobileTableComp
                  key={index}
                  title={item.title}
                  content={item.content}
                  paid={true}
                  plan={selectedPlan}
                />
              ))}
            </div>

            <div className=" w-full mt-[30px]">
              <p className="mt-[10px] text-[14px] leading-[18px] md:text-[16px] md:leading-[24px]">
                {t("product.table_footer1")}
              </p>
              <p className="mt-[10px] md:mt-[20px]  text-[14px] leading-[18px] md:text-[16px] md:leading-[24px]">
                <Trans components={{ br: <br /> }}>
                  {t("product.table_footer2")}
                </Trans>
              </p>

              <ul className="mt-[10px] text-[14px] leading-[18px] md:text-[16px] md:leading-[24px] list-disc ml-[25px]">
                <li className="mt-[10px] md:mt-[20px]">
                  {t("product.table_footer-list1")}
                </li>
                <li className="mt-[10px] md:mt-[20px]">
                  {t("product.table_footer-list2")}
                </li>
                <li className="mt-[10px] md:mt-[20px]">
                  {t("product.table_footer-list3")}
                </li>
              </ul>

              <p className="mt-[10px] md:mt-[20px]  text-[14px] leading-[18px] md:text-[16px] md:leading-[24px]">
                {t("product.table_footer-text1")}
              </p>
            </div>
            <div>
              <p className="mt-[20px] leading-[20px] md:text-[18px] md:leading-[30px]">
                {t("product.table_footer-text2")}
              </p>
            </div>


            <div className="flex flex-col md:flex-row justify-between mt-[30px] px-1 w-full gap-[20px] max-w-[738px]">
            {InsuranceHelper.getDocuments().map((document, index) => (
              <a
                key={index}  // Add a key for each item in the list
                className="flex items-start bg-white p-5 w-full shadow-[0_4px_10px_0px_rgba(0,0,0,0.12)] rounded-[10px]"
                href={document.fileURL} 
                target="_blank"
              >
                <div className="flex-shrink-0">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.25 11.875H19.25V4.375H11.75V11.875H6.75L15.5 20.625L24.25 11.875ZM6.75 23.125V25.625H24.25V23.125H6.75Z"
                      fill="#BA5E98"
                    />
                  </svg>
                </div>
                <span className="ml-5 font-semibold text-[18px] leading-[30px] text-left">
                  {document.title}
                </span>
              </a>
            ))}
            </div>
            
          </div>

         
          {/* BUTON FIXED*/}
          <div className="flex flex-col fixed bottom-[20px] w-[94%] md:sticky md:top-[140px] md:bottom-auto rounded-[10px] px-10 py-4 md:w-6/12  md:block md:ml-10 shadow-[0_4px_10px_0px_rgba(0,0,0,0.12);] h-fit mt-5 md:mt-0 bg-babymam-100">
            <div className="pb-4 flex flex-col text-babymam-900 text-[20px] top-138">
              <div className="mt-4 flex flex-row justify-between flex-wrap">
                <span className="font-semibold w-[70%] md:min-w-[140px] md-w-full md-mr-[10px]">
                  {t("product.table_footer-title")}
                </span>
                <span>{printPriceForCheckout(country)} €</span>
              </div>
            </div>

            <NavLink
              to={checkoutUrl}
              state={{ plan: selectedPlan === "paid" ? "upgraded" : "basic" }}
            >
              <Button desk="18" mobile="16" onClick={handleButtonClick}>
                {t("buttons.sign_up")}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSection;
