import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";

const PaymentForm = ({ handleConfirmation }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        /* confirmParams: {
          return_url: "https://example.com/order/123/complete",
        }, */
      })
      .then((result) => {
        // Handle result.error or result.paymentIntent

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setError(result.error.message);
          console.log(result.error.message);
          console.log(error);
        } else {
          handleConfirmation();
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ paymentMethodOrder: [ 'card', 'sepa_debit'] }}/>

      <button
        className={
          "mt-4 bg-babymam-600 border border-babymam-600 hover:bg-babymam-700 hover:border-babymam-700 active:bg-babymam-800 active:border-babymam-800 text-white shadow-[0px_3px_6px_0px_rgba(119,50,92,0.20)] w-full py-3 px-5 transition duration-300 uppercase font-semibold text-[16px] md:text-[20px] rounded"
        }
        disabled={!stripe}
      >
        {t("buttons.next")}
      </button>
      {error && <div className="text-red-500">{error}</div>}
    </form>
  );
};

export default PaymentForm;
