import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Medical } from "../images/medical.svg";
import { ReactComponent as Shield } from "../images/shield.svg";
import { ReactComponent as ProductBaby } from "../images/product-baby.svg";

const StepperProduct = () => {
  const { t } = useTranslation();
  return (
    <div className="md:flex w-full justify-between  gap-10 items-start ">
      <div className="text-center md:w-4/12">
        <div className="flex items-center justify-center">
          <Medical />
        </div>
        <div>
          <p className="mt-2 font-semibold text-babymamGray-800 text-[16px] md:text-[18px] font-sans leading-7 ">
            {t("stepper.title1")}
          </p>
        </div>
      </div>
      <div className="text-center md:w-4/12 mt-[15px] md:mt-0">
        <div className="flex items-center justify-center">
          <Shield />
        </div>
        <div >
          <p className="mt-2 font-semibold text-babymamGray-800 text-[16px] md:text-[18px] font-sans leading-7 ">
          {t("stepper.title2")}
          </p>
        </div>
      </div>
      <div className="text-center md:w-4/12 mt-[15px] md:mt-0 ">
        <div className="flex items-center justify-center">
          <ProductBaby />
        </div>
        <div>
          <p className="mt-2 font-semibold text-babymamGray-800 text-[16px] md:text-[18px] font-sans leading-7">
          {t("stepper.title3")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepperProduct;
