import React from "react";

const Button = ({
  children,
  onClick,
  theme = "babymam",
  disabled,
  mobile = "16",
  desk = "18",
  id,
  fullWidth = true
}) => {
  return (
    <button
      className={`${
        disabled
          ? "bg-babymamGray-100 text-babymamGray-300 border border-0 cursor-not-allowed"
          : theme === "white"
          ? "bg-white text-babymam-600 border border-babymam-600 hover:text-babymam-700 hover:border-babymam-700 active:text-babymam-800 active:border-babymam-800"
          : "bg-babymam-600 border border-babymam-600 hover:bg-babymam-700 hover:border-babymam-700 active:bg-babymam-800 active:border-babymam-800 text-white shadow-[0px_3px_6px_0px_rgba(119,50,92,0.20)]"
      } ${fullWidth ? 'w-full' : 'w-auto'}  py-3 px-8 transition duration-300 __uppercase font-semibold text-[${mobile}px] md:text-[${desk}px] rounded flex items-center justify-center gap-2`}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
