import React from "react";
import { useTranslation } from "react-i18next";
import TableSection from "./sections/TableSection";
import ClientAlready from "./sections/ClientAlready";

const SignUp = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full p-10 md:pt-10 pb-5 md:pb-5 bg-babymamGray-100 md:px-48 justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="md:mx-auto">
            <h1 className="text-[26px] md:text-[36px] font-bold font-serif text-babymam-900">
              {t("signup.title")}
            </h1>
            <p className="mt-4 text-[18px] md:text-[20px]">{t("signup.subtitle")}</p>
          </div>
        </div>
      </div>

      <TableSection title={false} />

      <div className="w-full justify-center text-center">
        <div className="bg-leafs bg-cover p-5 md:p-10">
          <ClientAlready />
        </div>
      </div>
    </>
  );
};

export default SignUp;
