import React from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Button from "../../components/Button";

const PromotionSection = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col md:flex-row items-center mx-auto">
      {/* Image as Background on Desktop */}
      <div
        className="w-full h-[350px] md:h-[450px] relative bg-cover hidden md:block"
        style={{
          backgroundImage: `url(${require('../../images/promotion_large.png')})`,
        }}
      >
        {/* Text Content Overlay */}
        <div className="absolute inset-y-0 right-0 flex items-center p-8 md:w-1/2">
          <div className="text-left">
            <h2 className="headline-medium mb-4">
              {t('our_insurance.promotion_heading')}
            </h2>
            <p className="text-[20px] mb-4">
              {t('our_insurance.promotion_text')}
            </p>
            <NavLink to="/product">
              <Button desk="20" mobile="18" fullWidth={false}>
              {t('our_insurance.promotion_button')}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="w-full md:hidden flex flex-col items-center">
        <img
          src={require('../../images/promotion_small.png')} // Replace with the mobile image path
          alt="Mother and Child"
          className="w-full h-auto mb-6"
        />

        {/* Text Content for Mobile */}
        <div className="text-left mx-8 mb-8">
          <h2 className="text-[28px] font-bold text-babymam-900 mb-4 leading-tight">
            {t('our_insurance.promotion_heading')}
          </h2>
          <p className="text-[18px] text-gray-700 mb-4">
            {t('our_insurance.promotion_text')}
          </p>

          {/* Button */}
          <NavLink to="/product">
            <Button desk="20" mobile="18">
              {t('our_insurance.promotion_button')}
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PromotionSection;