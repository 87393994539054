import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from "react";
import InsuranceHelper from "../helpers/InsuranceHelper";
import { ReactComponent as Close } from "../images/close.svg";

export const LegalDocumentsPopup = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [canClose, setCanClose] = useState(false); // Control when the popup can be closed
    const popupContentRef = useRef(null);

    const delay = 600; // Define delay in milliseconds

    // Expose the `display` method to the parent via ref
    useImperativeHandle(ref, () => ({
        display() {
            setIsVisible(true); 
            setCanClose(false); // Disable closing initially
            setTimeout(() => {
                setCanClose(true); // Allow closing after delay
            }, delay);
        },
        onClose() {
            if (canClose) {
                setIsVisible(false); // Close only if allowed
            }
        }
    }));

    // Add or remove `overflow: hidden` to the body based on visibility
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = "hidden"; // Prevent background scroll
        } else {
            document.body.style.overflow = ""; // Restore scroll
        }

        return () => {
            document.body.style.overflow = ""; // Clean up in case the component unmounts
        };
    }, [isVisible]);


    const clickBlocker = (event) => {
      event.stopPropagation(); // Prevent clicks from closing the dialog when inside
    };

    if (!isVisible) return null; // Render nothing when not visible

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 w-full" onClick={() => ref.current.onClose()}>
        <div className="bg-babymam-100 pt-[40px] pb-[20px] pl-[20px] pr-[30px] md:pl-[40px]  rounded-md w-[320px] md:w-[420px] md:max-w-[420px] max-h-[680px] md:max-h-[680px] relative flex flex-col" onClick={clickBlocker}>
          <div className="absolute right-[24px] md:right-[25px] top-3">
            <button onClick={() => ref.current.onClose()} className="text-babymam-600 focus:outline-none">
              <Close />
            </button>
          </div>
          <div
            className="overflow-auto flex-grow md:pr-[10px] scrollbar-thin scrollbar-webkit"
            ref={popupContentRef}
          >
            <div className="mb-6 text-left mt-4">
            {InsuranceHelper.getDocuments().map((document, index) => (
              <a
                key={index}  // Add a key for each item in the list
                className="flex items-start bg-white p-5 mt-4 w-full shadow-[0_4px_10px_0px_rgba(0,0,0,0.12)] rounded-[10px]"
                href={document.fileURL} 
                target="_blank"
              >
                <div className="flex-shrink-0">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.25 11.875H19.25V4.375H11.75V11.875H6.75L15.5 20.625L24.25 11.875ZM6.75 23.125V25.625H24.25V23.125H6.75Z"
                      fill="#BA5E98"
                    />
                  </svg>
                </div>
                <span className="ml-5 font-semibold text-[18px] leading-[30px] text-left">
                  {document.title}
                </span>
              </a>
            ))}
            </div>
          </div>          
        </div>
      </div>
    );
  });

export default LegalDocumentsPopup;