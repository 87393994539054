import { ReactComponent as CloseIcon } from "../images/close-icon.svg";
import { useTranslation } from "react-i18next";

const Modal = ({onClose}) => {
  const { t } = useTranslation();
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center h-screen py-10 px-5 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-babymamGray-0 opacity-40"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom px-5 py-[40px] bg-[#FFFF] text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-lg sm:w-full rounded-[10px] border-primary border-[1px]">
          <button
            onClick={onClose}
            className="absolute top-0 right-2 p-5 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <CloseIcon />
          </button>

          <div className="flex flex-col justify-center">
            <div className="mt-3 text-center">
              <h3 className="text-[22px] font-semibold text-babymam-900">
               {t("contactform.modal")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
