import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useParams } from 'react-router-dom';

const ArticleSection = () => {
  const { t } = useTranslation();
  const { countryCode } = useParams(); 
  const articles = t('our_insurance.articles', { returnObjects: true }) || [];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextArticle = () => {
    if (currentIndex + 1 < articles.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevArticle = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="max-w-[1200px] mx-auto px-8 py-8">
      {/* Header */}
      <h2 className="headline-medium">
      {t('our_insurance.articles_heading')}
      </h2>
      <p className="text-[18px] md:text-[20px] text-gray-700 mb-8">
      {t('our_insurance.articles_text')}
      </p>

      {/* Articles Grid on Desktop, Single Article on Mobile */}
      <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-8">
        {Array.isArray(articles) && articles.map((article) => (
          <div key={article.id} className="p-4">
            <img src={article.image} alt="" className="w-full h-[180px] rounded-lg mb-4 object-cover" />
            <h3 className="text-[22px] md:text-[22px] font-bold text-gray-900 mb-2">
              {article.title}
            </h3>
            <p className="text-[16px  md:text-[18px] text-gray-600 mb-4 space-y-4" dangerouslySetInnerHTML={{ __html: article.description }} />
            <NavLink to={`/${countryCode}/article/${article.id}`}  className="text-babymam-600 text-[20px] md:text-[20px] font-bold flex items-center">
              {t('our_insurance.articles_readmore')}
              <span className="ml-2 text-babymam-600">
                &rarr; {/* Right arrow icon */}
              </span>
            </NavLink>
          </div>
        ))}
      </div>

      {/* Mobile View - Only one article at a time */}
      <div className="md:hidden">
        <div className="p-4">
          {/* Image Placeholder */}
          <img src={articles[currentIndex].image} alt="" className="w-full h-[180px] rounded-lg mb-4 object-cover" />
          <h3 className="text-[22px] font-bold text-gray-900 mb-2">
            {articles[currentIndex].title}
          </h3>
          <p className="text-[16px] text-gray-600 mb-4 space-y-4" dangerouslySetInnerHTML={{ __html: articles[currentIndex].description }} />
          {/* Read More Link */}
          <NavLink to={`/${countryCode}/article/${articles[currentIndex].id}`}   className="text-babymam-600 text-[20px] font-bold flex items-center">
            {t('our_insurance.articles_readmore')}
            <span className="ml-2 text-babymam-600">
              &rarr; {/* Right arrow icon */}
            </span>
          </NavLink>
        </div>

        {/* Navigation Arrows */}
        <div className="mt-6 flex justify-center space-x-4">
          {/* Left Arrow */}
          <button
            onClick={prevArticle}
            className="p-2"
            disabled={currentIndex === 0}
          >
            <img className="w-[56px] h-[56px]"
              src={
                currentIndex === 0
                  ? require('../../images/arrow_left_disabled.png')
                  : require('../../images/arrow_left_active.png')
              }
            />
          </button>

          {/* Right Arrow */}
          <button
            onClick={nextArticle}
            className="p-2"
            disabled={currentIndex === articles.length - 1}
          >
            <img className="w-[56px] h-[56px]"
              src={
                currentIndex === articles.length - 1
                  ? require('../../images/arrow_right_disabled.png')
                  : require('../../images/arrow_right_active.png')
              }
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;