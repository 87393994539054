import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Calendar } from "../images/calendar.svg";
import { ReactComponent as Download } from "../images/download-icon-pink.svg";
import { ReactComponent as PeopleStar } from "../images/people-circle.svg";
import { ReactComponent as Mobile } from "../images/star-mobile.svg";
import { ReactComponent as People } from "../images/star-people.svg";
import { ReactComponent as MobilePhone1 } from "../images/phone1.svg";
import { ReactComponent as MobilePhone2 } from "../images/phone2.svg";
import { ReactComponent as MobilePhone3 } from "../images/phone3.svg";
import { ReactComponent as MobilePhoneMobile1 } from "../images/phone-small-1.svg";
import { ReactComponent as MobilePhoneMobile2 } from "../images/phone-small-2.svg";
import { ReactComponent as MobilePhoneMobile3 } from "../images/phone-small-3.svg";
const Tracker = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-[8px] md:mt-0 bg-cover bg-white md:bg-tracker-desktop md:h-[500px] max-w-[1728px] mx-auto bg-center bg-no-repeat relative md:static">
        <div className="text-babymam-900 md:px-4 md:flex items-center  md:max-w-[808px] md:h-full">
          <div className="md:hidden test z-9 w-full flex justify-center items-center relative">
            <div className="w-full h-full">
                <img
                    src={require("../images/home/home-background-mobile@x2-min.png")}
                    alt="Baby Mam"
                    className="w-full h-full -z-10"
                />
            </div>
            <div
              className="absolute top-[105px] transform -translate-y-1/2 text-center"
              style={{ padding: "60px 20px 20px" }}
            >
              {/* {MOBILE VIEW} */}
              
                <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900 md:hidden">
                <Trans components={{ br: <br /> }}>
                  {t("tracker.title-babymam")}
                  </Trans>
                </h1>

              <p className="mt-2 text-[18px] md:text-[20px] text-babymam-900 font-sans">
                {t("tracker.subtitle")}
              </p>
            </div>
          </div>

          <div className="hidden md:block md:ml-32 px-5 md:px-0 text-[18px] md:text-[24px]">
            {/* {DESKTOP VIEW} */}
            <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900">
              <Trans components={{ br: <br /> }}>
                {t("tracker.title-babymam")}
              </Trans>
            </h1>
            <p className="mt-2 text-[18px] md:text-[20px] text-babymam-900 font-sans">
              {t("tracker.subtitle")}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full px-10 md:px-0 flex py-[30px] bg-leafs bg-cover justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="flex flex-col md:max-w-[820px] rounded mx-auto items-center">
            <p className="text-babymam-900 font-semibold text-[22px] md:text-[30px] font-serif">
              {t("tracker.leafs_title")}
            </p>
            <p className="text-babymam-900 mt-[10px] text-[18px] md:text-[20px]">
              {t("tracker.leafs_desc")}
            </p>
            <div className="flex  flex-col  md:flex-row items-center md:justify-center  mt-[1.25rem] ">
              <Link to="https://play.google.com/store/apps/details?id=it.babymam" target="_blank">
                <img
                  className="w-[190px] md:w-auto md:mr-10 "
                  src={require("../images/google.png")}
                  alt="google"
                ></img>
              </Link>
              <Link
                to="https://apps.apple.com/app/id1544439688"
                target="_blank"
                className="w-[190px] md:w-auto mt-[20px] md:mt-0"
              >
                <img src={require("../images/apple.png")} alt="apple"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* {SECTION 1} */}
      <div className="w-full bg-white justify-center justify-items-center md:mt-[40px]">
        <div className="flex flex-col justify-center items-center md:flex-row max-w-[1188px] mx-auto  bg-babymamGray-100 md:bg-white ">
          <div className=" flex justify-center bg-babymamGray-100 md:bg-white   md:px-[80px] ">
            <div className=" relative hidden md:block md:h-[350px] md:w-[189px] overflow-visible bg-babymamGray-100 md:bg-white ">
              <MobilePhone1 className="  md:block hidden absolute inset-0 h-[380px] w-[189px]" />
            </div>

            <MobilePhoneMobile1 className="object-cover md:hidden  pt-[20px]" />

          </div>
          <div className="w-full md:w-9/12 md:pl-[40px] md:pr-[60px] py-[40px] px-[20px] bg-white ">
            <h2 className="text-[24px] md:text-[30px] font-bold font-serif mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("tracker.section_title1")}
            </h2>
            <div className="mt-[15px] md:mt-[20px]">
              <p className="text-[18px] md:text-[20px]">
                {t("tracker.section_desc1")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="w-full bg-white justify-center justify-items-center ">
        <div className="flex flex-col justify-center items-center md:flex-row-reverse max-w-[1188px] mx-auto bg-babymamGray-100 md:bg-white">
          <div className=" flex justify-center bg-babymamGray-100 md:bg-white md:px-[80px]">
            <div className=" relative hidden md:block md:h-[350px] md:w-[189px] overflow-visible bg-babymamGray-100 md:bg-white">
              <MobilePhone2 className="  md:block hidden absolute inset-0 h-[380px] w-[189px]" />
            </div>
            <MobilePhoneMobile2 className="object-cover md:hidden  pt-[20px]" />
          </div>
          <div className="w-full md:w-9/12 md:pr-[40px] md:pl-[60px] py-[40px] px-[20px] bg-white">
            <h2 className="text-[24px] md:text-[30px] font-bold font-serif mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("tracker.section_title2")}
            </h2>
            <div className="mt-[15px] md:mt-[20px]">
              <p className="text-[18px] md:text-[20px]">
                {t("tracker.section_desc2")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="w-full bg-white justify-center justify-items-center ">
        <div className="flex flex-col justify-center items-center md:flex-row max-w-[1188px] mx-auto md:pb-[40px] bg-babymamGray-100 md:bg-white">
          <div className=" flex justify-center md:self-start bg-babymamGray-100 md:bg-white md:px-[80px]">
            <div className=" relative hidden md:block md:h-[350px] md:w-[189px] overflow-visible bg-babymamGray-100 md:bg-white">
              <MobilePhone3 className="  md:block hidden absolute inset-0 h-[380px] w-[189px]" />
            </div>
            <MobilePhoneMobile3 className="object-cover md:hidden  pt-[20px]" />
          </div>
          <div className="w-full md:w-9/12 md:pl-[40px] md:pr-[60px] pt-[40px]  pb-[40px] px-[20px]  bg-white">
            <p className="text-[18px] md:text-[20px]">
              <Trans components={{ span: <span /> }}>
                {t("tracker.section_subtitle3")}
              </Trans>
            </p>

            <div className="mt-[15px] md:mt-[10px]">
              <ul className="text-[18px] md:text-[20px] list-disc flex flex-col gap-[10px] ml-[30px] ">
                <Trans components={{ li: <li />, span: <span /> }}>
                  {t("tracker.section_list1")}
                  {t("tracker.section_list2")}
                  {t("tracker.section_list3")}
                  {t("tracker.section_list4")}
                  {t("tracker.section_list5")}
                  {t("tracker.section_list6")}
                  {t("tracker.section_list7")}
                  {t("tracker.section_list8")}
                </Trans>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* EXPERIENCE SECTION */}
      <div className="w-full  bg-babymam-100 md:bg-babymamGray-100 justify-center justify-items-center px-[20px] md:px-20 py-10 max-w-[1728px] mx-auto ">
        <div
          className="max-w-[720px] flex flex-col justify-center  "
          style={{ margin: "0 auto" }}
        >
          <h2 className="text-[24px] md:text-[30px] font-bold mb-[30px] text-babymam-900 leading-[30px] md:leading-normal font-serif">
            {t("tracker.section_title4")}
          </h2>

          <div className="  pb-0 md:pb-0 md:mt-0 max-w-[720px]">
            <div className="flex flex-row">
              <div className="mr-5 hidden md:block">
                <People />
              </div>
              <div>
                <h2 className="text-babymam-900 font-semibold text-[18px] md:text-[22px]">
                  {t("tracker.section_title5")}
                </h2>
                <p className="text-babymam-900 md:text-babymamGray-700 text-[14px] md:text-[16px]">
                  {t("tracker.section_subtitle5")}
                </p>
              </div>
            </div>
            <div className="flex flex-row mt-[20px]">
              <div className="mr-5 hidden md:block">
                <Calendar />
              </div>
              <div>
                <h2 className="text-babymam-900 font-semibold text-[18px] md:text-[22px]">
                  {t("tracker.section_title6")}
                </h2>
                <p className=" text-babymam-900 md:text-babymamGray-700 text-[14px] md:text-[16px]">
                  {t("tracker.section_subtitle6")}
                </p>
              </div>
            </div>
            <div className="flex flex-row mt-[20px]">
              <div className="mr-5 hidden md:block">
                <Mobile />
              </div>
              <div>
                <h2 className="text-babymam-900 font-semibold text-[18px] md:text-[22px]">
                  {t("tracker.section_title7")}
                </h2>
                <p className=" text-babymam-900 md:text-babymamGray-700 text-[14px] md:text-[16px]">
                  {t("tracker.section_subtitle7")}
                </p>
              </div>
            </div>
            <div className="flex flex-row mt-[20px]">
              <div className="mr-5 hidden md:block">
                <PeopleStar />
              </div>
              <div>
                <h2 className="text-babymam-900 font-semibold text-[18px] md:text-[22px]">
                  {t("tracker.section_title8")}
                </h2>
                <p className=" text-babymam-900 md:text-babymamGray-700 text-[14px] md:text-[16px]">
                  {t("tracker.section_subtitle8")}
                </p>
              </div>
            </div>
            <div className="flex flex-row mt-[20px]">
              <div className="mr-5 hidden md:block">
                <Download />
              </div>
              <div>
                <h2 className="text-babymam-900 font-semibold text-[18px] md:text-[22px]">
                  {t("tracker.section_title9")}
                </h2>
              </div>
            </div>
          </div>

          <div className="w-full flex  justify-start md:justify-center  justify-items-center md:ml-[-58px]">
            <div className="flex  flex-col  md:flex-row items-center md:justify-center mt-[20px] md:mt-[-5px]">
              <Link to="https://play.google.com/store/apps/details?id=it.babymam" target="_blank">
                <img
                  className="w-[190px] md:w-auto md:mr-5 "
                  src={require("../images/google.png")}
                  alt="google"
                ></img>
              </Link>
              <Link
                to="https://apps.apple.com/app/id1544439688"
                target="_blank"
                className="w-[190px] md:w-auto mt-[20px] md:mt-0"
              >
                <img src={require("../images/apple.png")} alt="apple"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracker;
