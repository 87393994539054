import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

export const RedirectBasedOnCountry = () => {
  const { country } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!country) return; 

    const currentPath = location.pathname;
    const countryCodeLower = country?.toLowerCase();
    
    const pathSegments = currentPath.split('/').filter(Boolean); 
    if (pathSegments.length > 0 && ['es', 'it', 'fr', 'de', 'en'].includes(pathSegments[0].toLowerCase())) {
      pathSegments.shift(); 
    }

    // Verify yf country code is in the list already
    if (pathSegments[0] !== countryCodeLower) {
      const newPath = `/${countryCodeLower}/${pathSegments.join('/')}`;
      if (currentPath !== newPath) {
        navigate(newPath, { replace: true });
      }
    }
  }, [country, navigate, location.pathname]);

  return null; 
};

export default RedirectBasedOnCountry;


