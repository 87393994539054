import React , {useState}from "react";
import FAQList from "../../components/FAQComp";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const FAQSectionFull = ({ dataLeft, dataRight, principal, title = true }) => {
  const { t } = useTranslation();
  const [openAccordion, setOpenAccordion] = useState(null);

  return (
    <div className="w-full py-10 md:px-10  md:max-w-[1728px] justify-center justify-items-center">
      <div className="w-full justify-center text-center">
        <h2 className="text-[24px] md:text-[30px] font-bold text-babymam-900 font-serif leading-[30px] md:leading-normal">
          {title && t("faq.title")}
        </h2>
      </div>
      <div className="md:flex md:flex-row justify-center justify-items-center mt-5 px-5 md:px-0">
        <div className="md:w-6/12 md:pl-6 pr-5 md:pt-10">
          <FAQList data={dataLeft} openAccordion={openAccordion} setOpenAccordion={setOpenAccordion} />
        </div> 
        <div className="md:w-6/12 md:pl-6 pr-5 md:pt-10">
          <FAQList data={dataRight} openAccordion={openAccordion} setOpenAccordion={setOpenAccordion} />
          {principal && (
            <div className="flex flex-row justify-end text-sm mt-10">
              <NavLink to="/product">
                <span className="text-babymam-600 font-semibold text-[16px] md:text-[20px]">{t("faq.sign_up")}</span>
              </NavLink>
              <svg
                width="12"
                height="12"
                className="ml-2 mt-1"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50001 0.833374L7.97251 2.36087L14.0175 8.41671H0.833344V10.5834H14.0175L7.97251 16.6392L9.50001 18.1667L18.1667 9.50004L9.50001 0.833374Z"
                  fill="#BA5E98"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      {!principal && (
        <div className="flex flex-row justify-center text-sm mt-10">
          <NavLink to="/faq">
            <span className="text-babymam-600 font-semibold text-[16px] md:text-[20px]">{t("faq.all_faqs")}</span>
          </NavLink>
          <svg
            width="12"
            height="12"
            className="ml-2 mt-1"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.50001 0.833374L7.97251 2.36087L14.0175 8.41671H0.833344V10.5834H14.0175L7.97251 16.6392L9.50001 18.1667L18.1667 9.50004L9.50001 0.833374Z"
              fill="#BA5E98"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default FAQSectionFull;