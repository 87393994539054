import React from "react";
import Button from "../../components/Button";
import { NavLink } from "react-router-dom";

const ClientAlready = () => {
  return (
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-bold text-babymam-900 text-[22px] md:text-[28px]">
        Are you a client already?
      </h2>
      <p className="mt-4 text-babymam-900 text-[18px] md:text-[20px] leading-[23px] md:leading-normal">
        Get more benefits without hassles!
        <span className="font-semibold"> Tap the “UPGRADE NOW”</span> and get
        all the advantages of Premium Insurance in minutes.
      </p>
      <div className="w-full md:w-[31.25rem] mt-6">
        <NavLink to="/product/checkout" state={{ plan: "upgraded" }}>
          <Button desk="18" mobile="16">Upgrade Now</Button>
        </NavLink>
      </div>
      <p className="text-babymam-900 mt-4 text-xs font-semibold text-[14px] md:text-[16px]">
        Your insurance number won’t change.
      </p>
    </div>
  );
};

export default ClientAlready;
