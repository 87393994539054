import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../utils/isDesktop";
import { NavLink, useParams } from 'react-router-dom';
import PromotionSection from "./sections/PromotionSection";

const Article = () => {
  const { t } = useTranslation();
  const { countryCode, id } = useParams(); // Get the country code and article ID from the URL
  const articles = t('our_insurance.articles', { returnObjects: true }) || [];
  const isDesktop = useIsDesktop();
  const [content, setContent] = useState('');
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    // This ensures that the effect is only triggered when the ID changes
    const currentArticle = articles.find(article => article.id === id);

    if (currentArticle) {
      setArticle(currentArticle);

      const controller = new AbortController(); // Create an AbortController
      const signal = controller.signal;

      // Fetch the HTML file from the public folder
      fetch(currentArticle.html, { signal })
        .then(response => response.text())
        .then(data => setContent(data))
        .catch(err => {
          if (err.name !== 'AbortError') { // Ignore abort errors
            console.error('Error loading article:', err);
          }
        });

      // Filter out the current article and select related articles
      const related = articles.filter(article => article.id !== id).slice(0, 3);
      setRelatedArticles(related);

      // Cleanup function to cancel the fetch request
      return () => {
        controller.abort(); // Abort the fetch if the component unmounts
      };
    } else {
      setContent('<p>Article not found.</p>'); // Fallback if article is not found
    }
  }, [id]); // Only trigger this effect when `id` changes, not `articles`

  return (
    <div className="relative w-full">
      <div className="max-w-screen-xl px-4 lg:px-0 mx-auto lg:max-w-5xl">

        <div className="relative flex flex-col items-center md:items-start justify-center md:h-[408px] md:overflow-hidden bg-cover bg-center rounded-xl"
          style={isDesktop ? { backgroundImage: `url(${article?.image})` } : {}}>
          <img src={article?.image} alt="" className="block md:hidden w-full h-auto object-cover rounded-xl" />
        </div>

        {/* The main layout with two columns */}
        <div className="flex flex-col lg:flex-row lg:gap-8">
          
          {/* Main Content */}
          <div className="lg:w-2/3 w-full">
            <div className="article py-6">
              <h1>{article?.title}</h1>
              {/* HIDDEN FOR NOW 
              <div className="flex flex-wrap text-sm text-gray-500 mb-4 space-x-4">
                <p className="text-sm text-gray-500 my-4">
                  <span className="inline-flex items-center whitespace-nowrap">
                    <img src="/images/icons/icon_calendar.png" className="inline-block w-5 h-5 mr-1" />
                    {article?.date}
                  </span>
                  {" - "}
                  <span className="inline-flex items-center whitespace-nowrap">
                    <img src="/images/icons/icon_timer.png" className="inline-block w-5 h-5 mr-1" />
                    {article?.readTime} min read
                  </span>
                </p>
              </div>
              */}
              {/* Article Content */}
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>

          {/* Related Articles */}
          <aside className="lg:w-1/3 w-full lg:mt-0 mt-8">
            <div className="mt-4 p-4">
              <h2 className="headline-medium">{t("our_insurance.articles_related")}</h2>
              
              {relatedArticles.map((relatedArticle) => (
                <div className="my-4" key={relatedArticle.id}>
                  <NavLink to={`/${countryCode}/article/${relatedArticle.id}`}>
                    <img src={relatedArticle.image} alt={relatedArticle.title} className="w-full md:h-[126px] object-cover mb-2 rounded-xl" />
                    <h3 className="headline-small font-sans mt-2 mb-2">{relatedArticle.title}</h3>
                  </NavLink>
                  {/* HIDDEN FOR NOW
                  <p className="text-sm text-gray-500 my-4">
                    <span className="inline-flex items-center whitespace-nowrap">
                      <img src="/images/icons/icon_calendar.png" className="inline-block w-5 h-5 mr-1" />
                      {relatedArticle.date}
                    </span>
                    {" - "}
                    <span className="inline-flex items-center whitespace-nowrap">
                      <img src="/images/icons/icon_timer.png" className="inline-block w-5 h-5 mr-1" />
                      {relatedArticle.readTime} min read
                    </span>
                  </p>
                  */}
                  <p className="mb-8 space-y-4" dangerouslySetInnerHTML={{ __html: relatedArticle.description }} />
                </div>
              ))}                
            </div>
          </aside>  
        </div>
      </div>

      {/* Promotion Section for Mobile (inside flow) */}
      <div className="block lg:hidden mt-8 ">
        <PromotionSection />
      </div>

      {/* Promotion Section for Desktop (spans full viewport width) */}
      <div className="hidden lg:block bottom-0 left-0 w-full">
        <PromotionSection />
      </div>
    </div>
  );
}

export default Article;