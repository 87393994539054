import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
//import { handleDownload } from "../utils/handleDownload";
import { useLanguage } from "../contexts/LanguageContext";
import InsuranceHelper from "../helpers/InsuranceHelper";
import ContentHelper from "../helpers/ContentHelper";

export const FAQComp = ({ id, title, content, product, openAccordion, setOpenAccordion }) => {
  const { t } = useTranslation();
  const isCurrentAccordionOpen = openAccordion === id;
  const { country, setCountry } = useLanguage();

  const toggleCollapse = () => {
    setOpenAccordion(isCurrentAccordionOpen ? null : id);
  };

  /*
  const ipidNavLink = (
    <a
      className="font-semibold text-babymam-600"
      href={InsuranceHelper.getIpidURL()}
      target="_blank"
    >
      <span>{t('signform.ipid')}</span>
    </a>
  );

  const termsNavLink = (
    <a
      className="font-semibold text-babymam-600"
      href={InsuranceHelper.getTermsAndConditionsURL()}
      target="_blank"
    >
    </a>
  );

  const masterPolicyLink = (
    <a
      className="font-semibold text-babymam-600"
      href={t('signform.chubbMastePolicyUrl')}
      target="_blank"
      rel="noopener noreferrer">
    </a>
  );

  const renderContentWithLineBreaks = () => {
    return t(content).split('\n').map((paragraph, index) => (
      // <p className="m-2" key={index}>{paragraph}</p>
      <p className="m-2" key={index}>
      <Trans
        components={{
          span: <span />,
          ul: <ul />,
          li: <li />,
          p: <p />,
          br: <br />,
          a: <a/>,
          div:<div/>,
          table:<table/>,
          td:<td/>,
          tr:<tr/>,
          thead:<thead/>,
          th:<th/>,
          tbody:<tbody/>,
          linkIpid: ipidNavLink,
          linkTerms: termsNavLink,
          linkMasterPolicy: masterPolicyLink
        }}
      >
        {paragraph}
      </Trans>
    </p>
    ));
  };
  */
 
  return (
    <div
      className={`${
        product
          ? "border-b border-babymamGray-1 last:border-b-0"
          : "border-b border-babymam-600"
      } p-5 `}
    >
      <button
        className="text-babymamGray-700 mb-2 flex row w-full justify-between"
        onClick={toggleCollapse}
      >
        <div className="flex flex-row">
          {product && (
            <svg
              width="22"
              height="17"
              className="mt-1 mr-2"
              viewBox="0 0 27 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.69262 16.8124L2.43762 10.5574L0.307617 12.6724L8.69262 21.0574L26.6926 3.05738L24.5776 0.942383L8.69262 16.8124Z"
                fill="#D069AA"
              />
            </svg>
          )}
          <span className="font-semibold text-left leading-[23px] md:leading-normal text-[18px] md:text-[20px] md:font-bold">{ContentHelper.transformAppContent(title, country)}</span>
        </div>
        <svg
          width="13"
          height="9"
          className={`${isCurrentAccordionOpen ? "rotate-180" : ""} mt-2`}
          viewBox="0 0 13 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91 0.795044L6.5 5.37504L11.09 0.795044L12.5 2.20504L6.5 8.20504L0.5 2.20504L1.91 0.795044Z"
            fill="#4D4D4D"
          />
        </svg>
      </button>

      {isCurrentAccordionOpen && (
        <div className="mt-2 text-babymamGray-800 text-[14px] md:text-[18px]" dangerouslySetInnerHTML={{ __html: ContentHelper.transformAppContent(content, country) }} />
      )}
    </div>
  );
};

const FAQList = ({ data, openAccordion, setOpenAccordion }) => {
  return (
    <div>
      {Array.isArray(data) && data.map((item) => (
        <FAQComp
          key={item.id}
          id={item.id}
          title={item.title}
          content={item.content}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
        />
      ))}
    </div>
  );
};

export default FAQList;
