import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
/* import FAQSection from "./sections/FAQSection";
import StepperSection from "./sections/StepperSection"; */
import PhoneSection from "./sections/PhoneSection";
/* import BigNumberSection from "./sections/BigNumberSection";*/
import TableSection from "./sections/TableSection";
/* import { NavLink } from "react-router-dom";
import ClientAlready from "./sections/ClientAlready";
import Button from "../components/Button"; */
import ProductStepperSection from "./sections/ProductStepperSection";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import { useLanguage } from "../contexts/LanguageContext";

const Product = () => {
  const { t } = useTranslation();
  const phonesText = {
    title: t("product.phones_banner_title"),
    description:t("product.phones_banner_desc")
  }
  const { country } = useLanguage();
  const checkoutUrl = `/${country}/product/checkout`;
  const [selectedPlan, setSelectedPlan] = useState("paid");

  return (
    <>
      {/* TABLES */}
      <TableSection title={true} />

      {/* PHONES
      <PhoneSection  buddy={false} textData={phonesText}/>
       */}
    </>
  );
};

const handleButtonClick = () => {
   
  if (window.gtag) {
    window.gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'SignUpBanner'
    });
  }
};

export default Product;
