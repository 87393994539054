import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./views/Home";
import Product from "./views/Product";
import WhyInsurance from "./views/WhyInsurance";
import About from "./views/About";
import Article from "./views/Article";
import FAQ from "./views/FAQ";
import Contact from "./views/Contact";
import Checkout from "./views/Checkout";
import Purchase from "./views/Purchase";
import SignUp from "./views/SignUp";
import { ThemeProvider } from "@material-tailwind/react";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Cookies from "./views/Cookies";
import Tracker from "./views/Tracker";
import OurInsurance from "./views/OurInsurance";
import ProtectedRoute from "./components/ProtectedRoute";
import Blog from "./views/Blog";

const router = createBrowserRouter([
  {
    path: "/:countryCode",
    element: <App />,
    children: [
      {
        index: true,
        path: "",
        element: <Tracker />,
      },
      {
        // change here
        path: "our_insurance",
        element: <OurInsurance />,
      },
      {
        path: "product",
       
        children: [
          {
            index: true,
            element: <ProtectedRoute> <Product/></ProtectedRoute>,
          },
          {
            path: "checkout",
            element:<ProtectedRoute> <Checkout /></ProtectedRoute>, 
          },
          {
            path: "purchase",
            element:<ProtectedRoute> <Purchase /></ProtectedRoute>, 
          }
        ],
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "article/:id",
        element: <Article />,
      },
      {
        path: "article-1",
        element: <Article />,
      },
      {
        path: "kalle",
        element: <Article />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      { path: "why_insurance", element: <WhyInsurance /> },
      { path: "signup", element: <SignUp /> },
      { path: "privacy_policy", element: <PrivacyPolicy /> },
      { path: "cookies", element: <Cookies /> }
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Tracker /> },
     
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
