import InsuranceHelper from "./InsuranceHelper"

class ContentHelper {
   
  static transformAppContent(content, countryCode) {

    if (typeof content !== 'string') {
        console.log("==> content = ", content);
        return content;
    }

    var modifiedContent = content
      .replace(/&nbsp;/g,'\u00A0')
      .replace(/<ul/g, '<ul class="list-disc pl-5 mr-2"')
      .replace(/<li/g, '<li class="leading-[18px] md:leading-[25px] py-1"')
      .replace(/<p/g, '<p class="mb-2"')
      .replace(/<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/g, (match, href, innerContent) => {
      // Common classes for all <a> tags
      const commonClasses = 'text-babymam-600 font-bold underline';

      // Specific handling for different hrefs
      if (href === "app:insurance_benefits") {
        // Modify the href to "/product" without target="_blank"
        return match.replace(href, `/${countryCode?.toLowerCase()}/product`).replace('<a', `<a class="${commonClasses}"`);
      } else if (href === "app:insurance_doc_t-c") {
        // Modify for another case without target="_blank"
        return match.replace(href, InsuranceHelper.getTermsAndConditionsURL()).replace('<a', `<a class="${commonClasses}" target="_blank" rel="noopener noreferrer"`);
      } else if (href === "app:insurance_doc_ipid") {
        // Modify for another case without target="_blank"
        return match.replace(href, InsuranceHelper.getIpidURL()).replace('<a', `<a class="${commonClasses}" target="_blank" rel="noopener noreferrer"`);
      } else {
        // Default case with target="_blank" and common classes
        return match.replace('<a', `<a class="${commonClasses}" target="_blank" rel="noopener noreferrer"`);
      }
    });

    return modifiedContent
  }
}

export default ContentHelper;