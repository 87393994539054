import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import Textarea from "./Textarea";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const ContactForm = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const isButtonDisabled =
    name.trim() === "" ||
    email.trim() === "" ||
    subject.trim() === "" ||
    message.trim() === "";

  const isValidEmail = (value) => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate mandatory fields
    const errors = {};
    if (name.trim() === "") {
      errors.name = "Name is required";
    }
    if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address";
    } else if (email.trim() === "") {
      errors.email = "Email is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully!");

      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="text-sm">
        <div className="mb-4">
          <Input
            type="text"
            label={t("contactform.name")}
            placeholder={t("contactform.placeholdername")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            mandatory={true}
          />
          {errors.name && <p className="text-babymam-600">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <Input
            type="email"
            label={t("contactform.email")}
            placeholder={t("contactform.placeholderemail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mandatory={true}
          />
          {errors.email && <p className="text-babymam-600">{errors.email}</p>}
        </div>

        <div className="mb-4">
          <Input
            type="text"
            label={t("contactform.subject")}
            placeholder={t("contactform.placeholdersubject")}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <Textarea
            label={t("contactform.message")}
            value={message}
            placeholder={t("contactform.placeholdermessage")}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <Button desk="18" mobile="16" disabled={isButtonDisabled}>
        {t("contactform.send")}
        </Button>
      </form>

      {showModal && <Modal onClose={handleCloseModal} />}
    </>
  );
};

export default ContactForm;
