import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../../contexts/LanguageContext";
import Button from "../../components/Button";
import InsuranceHelper from "../../helpers/InsuranceHelper";
import ContentHelper from "../../helpers/ContentHelper";


const BenefitItem = ({ benefit, isOpen, toggle }) => {
  const { country } = useLanguage();
  var modifiedContent = ContentHelper.transformAppContent(benefit.content, country)

  return (
    <div className="border-b border-babymam-600 last:border-b-0 py-4">
    {/* Title and toggle button */}
    <div className="flex items-center justify-between cursor-pointer" onClick={toggle}>
      {/* Icon and title */}
      <div className="flex items-center gap-2">
        {/* Dynamically rendered icon */}
        <img src={`/images/${benefit.icon}`} className="w-[50px] h-[50px]"/>

        <p className="font-bold text-gray-800 text-[18px] md:text-[20px]">{benefit.title}</p>
      </div>

      {/* Toggle button (the + and -) centered vertically */}
      <button className="text-babymam-600 font-bold text-[16px] flex-shrink-0 text-[32] px-4">
        {isOpen ? "−" : "+"}
      </button>
    </div>

    {/* Render the HTML content with paragraphs when expanded */}
    {isOpen && (
      <div className="mt-2 text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
    )}
    </div>
  );
  
}
   

const BenefitsSection = ({ showLegalDocumentsPopup }) => {
  
  const { t } = useTranslation();
  const { country, setCountry } = useLanguage();
  const benefitsData = t('our_insurance.benefits', { returnObjects: true }) || [];
  const [openBenefitId, setOpenBenefitId] = useState(null);

  const toggleBenefit = (id) => {
    setOpenBenefitId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="flex flex-col md:flex-row md:justify-between gap-6 mt-8 p-4  max-w-[1200px] bg-white rounded-[10px] ">
      {/* Left side content */}
      <div className="md:w-1/2">
        <h2 className="headline-medium">{t('our_insurance.benefits_title')}</h2>
        <p className="mt-4 text-gray-700 text-[18px] md:text-[20px]">
        {t('our_insurance.benefits_text')}
        </p>
      </div>

      {/* Right side benefits list */}
      <div className="md:w-1/2 rounded-lg p-4">
        {Array.isArray(benefitsData) && benefitsData.map((benefit) => (
          <BenefitItem
            key={benefit.id}
            benefit={benefit}
            isOpen={openBenefitId === benefit.id}
            toggle={() => toggleBenefit(benefit.id)}
          />
        ))}

        {/* Button */}
        <div className="mt-6">
            <NavLink to={"/product"}>
                <Button desk="20" mobile="16">
                {t('our_insurance.benefits_button')}
                </Button>
            </NavLink>
        </div>
        <div className="text-[14px] text-center mt-4">
          {country === "FR" && (
            <Link className="text-purple-600 underline" onClick={showLegalDocumentsPopup}>
            {t('our_insurance.tc_apply')}
            </Link>
          )}          
        </div>       
      </div>
    </div>
  );
};

export default BenefitsSection;