import React, { useState } from "react";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { Trans, useTranslation } from "react-i18next";
import InsuranceHelper from "../helpers/InsuranceHelper";

const Contact = () => {
  const { t } = useTranslation();

  const [isExpanded1, setExpanded1] = useState(false);
  const [isExpanded2, setExpanded2] = useState(false);
  const [isExpanded3, setExpanded3] = useState(false);
  const [isExpanded4, setExpanded4] = useState(false);

  const toggleExpand1 = () => {
    setExpanded1(!isExpanded1);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
  };

  const toggleExpand2 = () => {
    setExpanded2(!isExpanded2);
    setExpanded1(false);
    setExpanded3(false);
    setExpanded4(false);
  };

  const toggleExpand3 = () => {
    setExpanded3(!isExpanded3);
    setExpanded1(false);
    setExpanded2(false);
    setExpanded4(false);
  };

  const toggleExpand4 = () => {
    setExpanded4(!isExpanded4);
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
  };

  const claimsFormUrl = InsuranceHelper.getClaimsFormURL();

  return (
    <>
      <div className="w-full py-10 px-5  bg-white md:bg-babymamGray-100 justify-center justify-items-center">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto mb-10">
            <h1 className="headline-big">
              {t("contact.title")}
            </h1>
          </div>
          <div className="flex flex-col md:w-6/12 md:mx-auto">
            <h2 className="text-babymam-900 text-[18px] md:text-[22px] font-semibold text-left mb-4 hidden md:block">
              {t("contact.title2")}
            </h2>
            <div className="shadow-lg bg-white rounded-lg px-6 py-5 mb-6">
              <button
                className="text-babymam-900  flex row w-full justify-between"
                onClick={toggleExpand1}
              >
                <div className="flex flex-row font-semibold">
                  <span className="text-[18px]">
                    {t("contact.claim_title")}
                  </span>
                </div>
                <svg
                  width="13"
                  height="9"
                  className="mt-2"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.91 0.795044L6.5 5.37504L11.09 0.795044L12.5 2.20504L6.5 8.20504L0.5 2.20504L1.91 0.795044Z"
                    fill="#4D4D4D"
                  />
                </svg>
              </button>

              {isExpanded1 && (
                <div className="mt-4 text-babymamGray-700 text-start">
                  <p className="text-[14px] md:text-[16px] leading-[24px] mb-4">
                    <Trans
                      components={{ a: <a />, br: <br />, span: <span /> }}
                    >
                      {t("contact.claim_desc", { claimsFormUrl })}
                    </Trans>
                  </p>
                  <p className="text-[14px] md:text-[16px] leading-[24px] mb-4">
                  <Trans
                      components={{ a: <a />, br: <br />, span: <span /> }}
                    >
                    {t("contact.claim_desc2", { claimsFormUrl })}
                    </Trans>
                  </p>
                </div>
              )}
            </div>
            <div className="shadow-lg bg-white rounded-lg px-6 py-5 mb-6">
              <button
                className="text-babymam-900 flex row w-full justify-between text-left"
                onClick={toggleExpand2}
              >
                <div className="flex flex-row font-semibold">
                  <span className="text-[18px] pr-2">
                    {t("contact.question_title")}
                  </span>
                </div>

                <svg
                  width="13"
                  height="9"
                  className="mt-2 min-w-[13px] min-h-[9px]"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.91 0.795044L6.5 5.37504L11.09 0.795044L12.5 2.20504L6.5 8.20504L0.5 2.20504L1.91 0.795044Z"
                    fill="#4D4D4D"
                  />
                </svg>
              </button>

              {isExpanded2 && (
                <div className="mt-4 text-babymamGray-700 text-start">
                  <p className="text-[14px] md:text-[16px] leading-[24px]">
                    <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.question_desc")}
                    </Trans>
                  </p>
                  <p className="text-[14px] md:text-[16px] leading-[24px] mb-4"> <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.question_desc2")}
                    </Trans></p>

                    <p className="text-[14px] md:text-[16px] leading-[24px] ">
                    <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.question_desc3")}
                    </Trans>
                  </p>
                  <p className="text-[14px] md:text-[16px] leading-[24px]"> <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.question_desc4")}
                    </Trans></p>
                  
                </div>
              )}
            </div>
            <div className="shadow-lg bg-white rounded-lg px-6 py-5 mb-6">
              <button
                className="text-babymam-900 flex row w-full justify-between "
                onClick={toggleExpand4}
              >
                <div className="flex flex-row font-semibold">
                  <span className="text-[18px]">
                    {t("contact.complaint_title")}
                  </span>
                </div>
                <svg
                  width="13"
                  height="9"
                  className="mt-2 min-w-[13px] min-h-[9px]"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.91 0.795044L6.5 5.37504L11.09 0.795044L12.5 2.20504L6.5 8.20504L0.5 2.20504L1.91 0.795044Z"
                    fill="#4D4D4D"
                  />
                </svg>
              </button>

              {isExpanded4 && (
                <div className="mt-4 text-babymamGray-700 text-start">
                  <p className="text-[14px] md:text-[16px] leading-[24px]">
                    <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.complaint_desc")}
                    </Trans>
                  </p>
                  <p className="text-[14px] md:text-[16px] leading-[24px] mb-4"> <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.complaint_desc2")}
                    </Trans></p>

                    <p className="text-[14px] md:text-[16px] leading-[24px] ">
                    <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.complaint_desc3")}
                    </Trans>
                  </p>
                  <p className="text-[14px] md:text-[16px] leading-[24px]"> <Trans components={{ p: <p />, span: <span />, a: <a /> }}>
                      {t("contact.complaint_desc4")}
                    </Trans></p>
                </div>
              )}
            </div>
            <h2 className="text-babymam-900 text-[18px] md:text-[22px] font-semibold text-left mt-4 mb-4 hidden md:block">
              {t("contact.title3")}
            </h2>
            <div className="shadow-lg bg-white rounded-lg px-6 py-5">
              <button
                className="text-babymam-900  flex row w-full justify-between text-left"
                onClick={toggleExpand3}
              >
                <div className="flex flex-row font-semibold">
                  <span className="text-[18px] pr-2">
                    {t("contact.problem_title")}
                  </span>
                </div>
                <svg
                  width="13"
                  height="9"
                  className="mt-2 min-w-[13px] min-h-[9px]"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.91 0.795044L6.5 5.37504L11.09 0.795044L12.5 2.20504L6.5 8.20504L0.5 2.20504L1.91 0.795044Z"
                    fill="#4D4D4D"
                  />
                </svg>
              </button>

              {isExpanded3 && (
                <div className="mt-4 text-babymamGray-700 text-start">
                  <p className="text-[14px] md:text-[16px]">
                    {t("contact.problem_desc")}
                  </p>
                  <div className="mt-6">
                    <ContactForm />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
