import React from "react";

const Input = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  type,
  mandatory = false,
}) => {
  return (
    <div>
      <label
        className="block text-babymamGray-800 mb-2 text-[16px] md:text-[18px]"
        htmlFor={label}
      >
        {label} {mandatory && <span className="text-babymam-600">*</span>}
      </label>
      <input
        className="appearance-none text-[16px] md:text-[18px] border border-babymamGray-400 rounded w-full py-2 px-3 text-babymamGray-800 leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Input;
