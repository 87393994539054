import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children, onCountryDetected }) => {
  const supportedCountries = ["EN", "ES", "IT", "DE"]; //"FR"
  const [country, setCountry] = useState(() => {
    const path = window.location.pathname.split("/");
    const potentialCountryCode = path[1].toUpperCase();
    return supportedCountries.includes(potentialCountryCode)
      ? potentialCountryCode
      : "EN";
  });

  const { languageCode } = useParams();

  useEffect(() => {
    const isManuallySelected = localStorage.getItem("isCountryManuallySelected") === "true";
    const savedCountry = localStorage.getItem("userSelectedCountry");

    const setAppropriateCountry = (countryCode) => {
      return supportedCountries.includes(countryCode) ? countryCode : "EN";
    };

    if (supportedCountries.includes(languageCode?.toUpperCase())) {
      const adjustedCountry = setAppropriateCountry(languageCode.toUpperCase());
      setCountry(adjustedCountry);
      localStorage.setItem("userSelectedCountry", adjustedCountry);
      localStorage.setItem("isCountryManuallySelected", "true");
    } else if (savedCountry && isManuallySelected) {
      setCountry(savedCountry);
    } else if (!savedCountry) {
      fetch("https://extreme-ip-lookup.com/json/?key=nSBCkMd21Re7MtFQ3XBe")
        .then((res) => res.json())
        .then((response) => {
          if (!isManuallySelected) {
            const countryToSet = setAppropriateCountry(response.countryCode);
            setCountry(countryToSet);
            localStorage.setItem("userSelectedCountry", countryToSet);
          }
        })
        .catch((error) => {
          console.error("Error detecting the country:", error);
          if (!isManuallySelected) {
            setCountry("EN");
            localStorage.setItem("userSelectedCountry", "EN");
          }
        });
    }
  }, [onCountryDetected, languageCode]);

  useEffect(() => {
    //console.log("LanguageContext, country changed = ", country);
  }, [country]);

  
  return (
    <LanguageContext.Provider value={{ country, setCountry }}>
      {children}
    </LanguageContext.Provider>
  );
};

