import React, { useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../components/Button";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
const About = () => {
  const { t } = useTranslation();
  const { country, setCountry } = useLanguage();
  const parentRef1 = useRef(null);
  const parentRef2 = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    const parentElement1 = parentRef1.current;
    const parentElement2 = parentRef2.current;
    // const parentElement3 = parentRef3.current;
    // const parentElement4 = parentRef4.current;
    // const parentElement5 = parentRef5.current;

    const childElement = childRef.current;

    const childWidth = childElement.offsetWidth;
    let paddingValue = 0;

    if (childWidth > 1200) {
      paddingValue = 20; // Adjust the calculation as needed
    }

    parentElement1.classList.add(`px-${paddingValue}`);
    parentElement2.classList.add(`px-${paddingValue}`);
    // parentElement3.classList.add(`px-${paddingValue}`);
    // parentElement4.classList.add(`px-${paddingValue}`);
    // parentElement5.classList.add(`px-${paddingValue}`);

    return () => {
      parentElement1.classList.remove(`px-${paddingValue}`);
      parentElement2.classList.remove(`px-${paddingValue}`);
      // parentElement3.classList.remove(`px-${paddingValue}`);
      // parentElement4.classList.remove(`px-${paddingValue}`);
      // parentElement5.classList.remove(`px-${paddingValue}`);
    };
  }, []);

  return (
    <>
      <div className="w-full py-10 px-5 md:px-[32px] justify-center justify-items-center">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto">
            <h1 className="headline-big">
              {t("about.title")}
            </h1>
            <p className="mt-5 text-[18px] md:text-[20px] leading-[23px] md:leading-normal">
              <Trans components={{ p: <p /> }}>{t("about.desc")}</Trans>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full p-10 md:py-[38px] bg-babymamGray-100 md:px-[60px] justify-center justify-items-center">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto">
            <h2 className="text-[22px] md:text-[28px] font-semibold font-sans text-babymam-900">
              {t("about.banner")}
            </h2>
          </div>
        </div>
      </div>

      {/* SECTION 1 */}
      <div className="w-full bg-white justify-center justify-items-center">
        <div
          ref={parentRef1}
          className="flex flex-col md:flex-row max-w-[1728px] mx-auto"
        >
          <div className="w-full md:w-4/12">
            <img
              src={require("../images/about/section1-min.png")}
              alt="group"
              className="w-full h-full object-cover"
            />
          </div>
          <div ref={childRef} className="w-full md:w-8/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.section1_title")}
            </h2>
            <div>
              <p className="text-[18px] md:text-[20px] md:leading-[30px]">
                <Trans components={{ p: <p /> }}>
                  {t("about.section1_desc")}
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="w-full bg-white justify-center justify-items-center">
        <div
          ref={parentRef2}
          className="flex flex-col  md:flex-row-reverse max-w-[1728px] mx-auto"
        >
          <div className="w-full md:w-4/12">
            <img
              src={require("../images/about/section2-min.png")}
              alt="group"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-8/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.section2_title")}
            </h2>
            <div>
              <p className="text-[18px] md:text-[20px] md:leading-[30px]">
                {t("about.section2_desc")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full p-10 md:py-20 bg-babymam-100 md:px-48 justify-center justify-items-center">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto">
            <h2 className="text-[22px] md:text-[28px] font-semibold font-sans text-babymam-900">
              {t("about.banner2")}
            </h2>
          </div>
        </div>
      </div> */}

      {/* SECTION 5 */}

      {/* <div className="w-full bg-white justify-center justify-items-center">
        <div ref={parentRef5} className="flex flex-col md:flex-row max-w-[1728px] mx-auto">
          <div className="w-full md:w-4/12">
            <img
              src={require("../images/section5.png")}
              alt="group"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-8/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.section5_title")}
            </h2>
            <div>
              <p className="text-[20px] md:text-[18px] md:leading-[30px]">{t("about.section5_desc")}</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* SECTION 3 */}
      {/* <div className="w-full bg-white justify-center justify-items-center">
        <div ref={parentRef3} className="flex flex-col md:flex-row-reverse max-w-[1728px] mx-auto">
          <div className="w-full md:w-4/12">
            <img
              src={require("../images/section3.png")}
              alt="group"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-8/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.section3_title")}
            </h2>
            <div>
              <p className="text-[20px] md:text-[18px] md:leading-[30px]">{t("about.section3_desc")}</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="w-full p-10 md:py-20 bg-babymamGray-100 md:px-48 justify-center justify-items-center ">
        <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
          <div className="md:mx-auto">
            <h2 className="text-[22px] md:text-[28px] font-semibold font-sans text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.banner3")}
            </h2>
          </div>
        </div>
      </div> */}

      {/* SECTION 4 */}
      {/* <div className="w-full bg-white justify-center justify-items-center">
        <div ref={parentRef4} className="flex flex-col md:flex-row max-w-[1728px] mx-auto">
          <div className="w-full md:w-4/12">
            <img
              src={require("../images/section4.png")}
              alt="group"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full md:w-8/12 px-5 py-10 md:px-20">
            <h2 className="text-[24px] md:text-[30px] font-bold mb-5 text-babymam-900 leading-[30px] md:leading-normal">
              {t("about.section4_title")}
            </h2>
            <div>
              <p className="text-[20px] md:text-[18px] md:leading-[30px]">{t("about.section4_desc")}</p>
            </div>
          </div>
        </div>
      </div> */}
      {country !== "EN" ? (
        <div className="bg-leafs bg-cover p-10  md:py-[30px] md:px-[10] ">
          <div className="w-full justify-center text-center max-w-[1728px] mx-auto">
            <div className="flex flex-col md:w-6/12 rounded mx-auto items-center">
              <p className="text-babymam-900 text-[18px] md:text-[20px] leading-[23px] md:leading-normal">
                <Trans components={{ span: <span /> }}>
                  {t("about.leafs_desc")}
                </Trans>
              </p>
              <div className="flex flex-row mt-5 w-full md:w-[31.25rem]">
                <NavLink className="w-full" to="/product">
                  <Button>{t("buttons.get_insurance")}</Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default About;
