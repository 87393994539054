import React, {useState} from "react";
import PhoneSection from "./sections/PhoneSection";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import FAQList from "../components/FAQComp";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [openAccordion, setOpenAccordion] = useState(null);

  const data = [
    {
      id: 1,
      title: "privacy_policy.policy_title1",
      content: "privacy_policy.policy_desc1",
    },
    {
      id: 2,
      title: "privacy_policy.policy_title2",
      content: "privacy_policy.policy_desc2",
    },
    {
      id: 3,
      title: "privacy_policy.policy_title3",
      content: "privacy_policy.policy_desc3",
    },
    {
      id: 4,
      title: "privacy_policy.policy_title4",
      content: "privacy_policy.policy_desc4",
    },
    {
      id: 5,
      title: "privacy_policy.policy_title5",
      content: "privacy_policy.policy_desc5",
    },
    {
      id: 6,
      title: "privacy_policy.policy_title6",
      content: "privacy_policy.policy_desc6",
    },
    {
      id: 7,
      title: "privacy_policy.policy_title7",
      content: "privacy_policy.policy_desc7",
    },
    {
      id: 8,
      title: "privacy_policy.policy_title8",
      content: "privacy_policy.policy_desc8",
    },
    {
      id: 9,
      title: "privacy_policy.policy_title9",
      content: "privacy_policy.policy_desc9",
    },
    {
      id: 10,
      title: "privacy_policy.policy_title10",
      content: "privacy_policy.policy_desc10",
    },
    {
      id: 11,
      title: "privacy_policy.policy_title11",
      content: "privacy_policy.policy_desc11",
    },
    {
      id: 12,
      title: "privacy_policy.policy_title12",
      content: "privacy_policy.policy_desc12",
    },
  ];
  return (
    <>
      <div className="w-full p-5 md:pt-20 md:px-48 justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="md:mx-auto">
            <h1 className="text-[28px] md:text-[36px] font-bold font-serif text-babymam-900">
              {t("privacy_policy.title")}
            </h1>
          </div>
        </div>
      </div>

      <div className="w-full p-5 md:p-20 flex justify-center justify-items-center">
        <div className="w-full md:max-w-[1000px]">
          <FAQList data={data} openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}/>
        </div>
      </div>

      {/* FIND */}
      <div className="w-full p-5 md:px-48 md:py-16 bg-leafs bg-cover justify-center justify-items-center">
        <div className="w-full justify-center text-center">
          <div className="md:w-6/12 rounded mx-auto p-10">
            <h2 className="text-[26px] md:text-[28px] font-bold text-babymam-900 mb-6">
              Can’t find what you are looking for?
            </h2>
            <NavLink to="/contact">
              <Button>Contact Us</Button>
            </NavLink>
          </div>
        </div>
      </div>

      {/* PHONES */}
      <PhoneSection faq={true} />
    </>
  );
};

export default PrivacyPolicy;
